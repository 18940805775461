import { useEffect, useRef, EffectCallback, DependencyList } from "react";

export function useEffectAfterMount(effect: EffectCallback, deps: DependencyList) {
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        if (isMounted.current) {
            return effect();
        } else {
            isMounted.current = true;
        }
    }, deps);

    // Reset on unmount; in React 18, components can mount again
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
}
