import { useCallback, useRef, useState } from "react";
import {
    ChildrenInfo,
    LineInfo,
    UniversalMapInfo,
} from "../../components/UniversalMap/UniversalMapData";
import { api } from "../api";
import { OasisDecodeError } from "../oasiserror";
import { FoldLinesForUnvisibleItems, toggleItemById } from "../../components/UniversalMap/UniversalMapHelpers";

export function useAnalyzeView({ name }: { name: string }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [data, setData] = useState<UniversalMapInfo | undefined>(undefined);
    const origLines = useRef<LineInfo>({ items: [] });

    async function fetchData() {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get<UniversalMapInfo>(
                `/api/analyzation/${name}`
            );
            origLines.current = response.data.lines;

            response.data.lines = FoldLinesForUnvisibleItems({
                origLines: origLines.current,
                actualScheme: response.data.scheme,
            });

            setData(response.data);
            setLoading(false);
        } catch (e: unknown) {
            setError(OasisDecodeError(e));
            setLoading(false);
        }
    }

    const toogleChildren = useCallback((id: string) => {
        setData((oldData) => {
            if (!oldData) return oldData;

            // toogle collapsed
            const newData: ChildrenInfo = {
                children: toggleItemById(id, oldData.scheme.children ?? []),
                childrenLayout: oldData.scheme.childrenLayout,
            };

            return {
                lines: FoldLinesForUnvisibleItems({
                    origLines: origLines.current,
                    actualScheme: newData,
                }),
                scheme: newData,
            };
        });
    }, []);

    return { loading, error, data, fetchData, toogleChildren };
}
