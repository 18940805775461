import React, {
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import { HEADER_HEIGHT } from "../components/Header";
import {
    Alert,
    Container,
    Grid,
    Paper,
    ScrollArea,
    Text,
    Table,
    Title,
    Center,
    Tooltip,
    Group,
    Button,
    UnstyledButton,
    LoadingOverlay,
    Anchor,
    useMantineTheme,
} from "@mantine/core";
import {
    useAIAnalysis,
    useAIAnalysisChangeset,
    useAIAnalysisIssues,
} from "../core/hooks/aianalysis";
import {
    IconAlertTriangle,
    IconMoodCheck,
    IconLoader,
    IconRefresh,
    IconCheck,
    IconCircle,
    IconMoodAngry,
    IconArrowBack,
    IconExternalLink,
} from "@tabler/icons-react";

import styles from "./AIAnalysis.module.scss";
import cx from "classnames";
import {
    IAIIssueCount,
    IAIStack,
    IAIStackIssue,
    StackAnalyzeState,
} from "../core/models/IAIAnalysis";
import { ShowModalError } from "../core/oasiserror";
import { AxiosError } from "axios";
import { useMediaQuery } from "@mantine/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { AutoRefreshLoader } from "../components/AutoRefreshLoader";
import InitSettingsContext from "../core/initsettings/InitSettingsContext";
import { useEffectAfterMount } from "../core/hooks/useEffectAfterMount";

function ShowIssueCounts(issues?: IAIIssueCount): ReactNode[] {
    let infoCol: ReactNode[] = [];
    if (!issues) return infoCol;

    if (issues.high !== 0) {
        infoCol.push(
            <Tooltip key="High" label="High">
                <Text className={styles.High}>{issues.high}</Text>
            </Tooltip>
        );
    }

    if (issues.medium !== 0) {
        infoCol.push(
            <Tooltip key="Medium" label="Medium">
                <Text className={styles.Medium}>{issues.medium}</Text>
            </Tooltip>
        );
    }

    if (issues.low !== 0) {
        infoCol.push(
            <Tooltip key="Low" label="Low">
                <Text className={styles.Low}>{issues.low}</Text>
            </Tooltip>
        );
    }

    if (infoCol.length === 0) {
        infoCol.push(
            <Tooltip key="No issues" label="No issues">
                <IconMoodCheck color="green" />
            </Tooltip>
        );
    }

    return infoCol;
}

function findScrollableParent(element: Element): Element | null {
    let parent = element.parentElement;
    while (parent) {
        const style = window.getComputedStyle(parent);
        if (style.overflow !== "visible" || style.overflowY !== "visible") {
            if (parent.scrollHeight > parent.clientHeight) {
                return parent;
            }
        }
        parent = parent.parentElement;
    }
    return null;
}

function AIStackRow({
    stack,
    isSelected,
    showDescription,
}: {
    stack: IAIStack;
    isSelected: boolean;
    showDescription: boolean;
}) {
    const navigate = useNavigate();
    const trRef = useRef<HTMLTableRowElement>(null);
    const { account } = useContext(InitSettingsContext);

    useEffect(() => {
        if (isSelected && trRef.current) {
            const scrollParent = findScrollableParent(trRef.current);
            if (scrollParent) {
                const { top: parentTop, bottom: parentBottom } =
                    scrollParent.getBoundingClientRect();
                const { top: elementTop, bottom: elementBottom } =
                    trRef.current.getBoundingClientRect();

                const isElementFullyVisible =
                    elementTop >= parentTop && elementBottom <= parentBottom;

                if (!isElementFullyVisible) {
                    trRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }
        }
    }, [isSelected]);

    let infoCol: ReactNode[] = [];

    switch (stack.state) {
        case StackAnalyzeState.NotAnalyzed:
            if (account && account?.isTrial && stack.isBigForTrial) {
                infoCol.push(
                    <Tooltip
                        key="notanalyzed"
                        label={
                            <Text>
                                Too large for Trial
                                <br />
                                Subscribe to analyze
                            </Text>
                        }
                    >
                        <IconAlertTriangle color="rgb(34, 139, 230)" />
                    </Tooltip>
                );
            } else {
                infoCol.push(
                    <Tooltip key="notanalyzed" label="Not analyzed">
                        <IconCircle color="rgb(34, 139, 230)" />
                    </Tooltip>
                );
            }
            break;
        case StackAnalyzeState.AnalyzeInProgress:
            infoCol.push(
                <Tooltip key="progress" label="Analyze in progress...">
                    <IconLoader />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.Analyzed:
            infoCol.push(ShowIssueCounts(stack.issues));
            break;
        case StackAnalyzeState.FixReady:
            infoCol.push(ShowIssueCounts(stack.issues));
            infoCol.push(
                <Tooltip key="fixready" label="Fix ready">
                    <IconMoodCheck color="orange" />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.ChangesetReady:
            infoCol.push(ShowIssueCounts(stack.issues));
            infoCol.push(
                <Tooltip key="changesetready" label="Changeset ready">
                    <IconMoodCheck color="darkorange" />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.FixInProgress:
            infoCol.push(ShowIssueCounts(stack.issues));
            infoCol.push(
                <Tooltip key="progress" label="Fix in progress...">
                    <IconLoader />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.ApplyInProgress:
            infoCol.push(ShowIssueCounts(stack.issues));
            infoCol.push(
                <Tooltip key="progress" label="Apply in progress...">
                    <IconLoader />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.ChangesetInProgress:
            infoCol.push(ShowIssueCounts(stack.issues));
            infoCol.push(
                <Tooltip
                    key="progress"
                    label="Changeset creation in progress..."
                >
                    <IconLoader />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.WontFix:
            infoCol.push(
                <Tooltip key="wontfix" label="Won't fix">
                    <IconMoodCheck color="green" />
                </Tooltip>
            );
            break;
        case StackAnalyzeState.Error:
        case StackAnalyzeState.ErrorAnalyze:
        case StackAnalyzeState.ErrorFix:
        case StackAnalyzeState.ErrorChangeset:
        case StackAnalyzeState.ErrorApply:
            infoCol.push(ShowIssueCounts(stack.issues));
            infoCol.push(
                <Tooltip key="alert" label={stack.error_text}>
                    <IconMoodAngry color="red" />
                </Tooltip>
            );
            break;
    }

    return (
        <tr
            ref={trRef}
            data-id={stack.id}
            className={cx(styles.stack, {
                [styles.selected]: isSelected,
            })}
            tabIndex={0}
            onClick={() => navigate(`/ai/stack/${stack.id}`)}
            onKeyDown={(e) =>
                e.key === "Enter" ? navigate(`/ai/stack/${stack.id}`) : ""
            }
        >
            <td>
                <Text c="dimmed" size="xs" lineClamp={1}>
                    {stack.cloud_name}
                </Text>
                <Text lineClamp={3}>{stack.name}</Text>
                {showDescription ? (
                    <Text c="dimmed" size="xs" lineClamp={4}>
                        {stack.description}
                    </Text>
                ) : null}
            </td>
            <td>
                <Group spacing="1px" position="right">
                    {infoCol}
                </Group>
            </td>
        </tr>
    );
}

function AIStacksView({
    data,
    selected,
    setSelected,
    refreshStacks,
    analyzeAll,
    showDescription,
}: {
    data?: IAIStack[];
    selected?: IAIStack;
    setSelected: (stack: IAIStack) => void;
    refreshStacks: (silent?: boolean) => void;
    analyzeAll: () => Promise<void>;
    showDescription: boolean;
}) {
    const theme = useMantineTheme();
    const isDark = theme.colorScheme === "dark";
    const { account } = useContext(InitSettingsContext);

    const [analyzeAllLoading, setAnalyzeAllLoading] = useState(false);
    const analyzeAllHandler = async () => {
        modals.openConfirmModal({
            title: "Analyze all deployments",
            centered: true,
            children: (
                <>
                    <Text size="sm">
                        You are about to initiate the "Analyze all" operation,
                        which will perform a comprehensive analysis of all
                        deployments that have not yet been analyzed.
                    </Text>
                    <Text size="sm" mt="sm">
                        Please note that this operation is billable, and charges
                        will be applied according to the pay-as-you-go rates.
                    </Text>
                </>
            ),
            labels: {
                confirm: "Analyze all",
                cancel: "Cancel",
            },
            confirmProps: { color: "blue" },
            onConfirm: async () => {
                setAnalyzeAllLoading(true);
                try {
                    await analyzeAll();
                    refreshStacks(true);
                    setAnalyzeAllLoading(false);
                } catch (e: unknown) {
                    refreshStacks(true);
                    setAnalyzeAllLoading(false);
                    ShowModalError(
                        `Cannot analyze all deployments`,
                        e as AxiosError
                    );
                }
            },
        });
    };

    return (
        <Paper
            shadow="md"
            radius="xl"
            withBorder
            pt="xl"
            pb="2rem"
            mt="sm"
            mb="sm"
            mr="sm"
        >
            <Title
                order={3}
                mb="xs"
                style={{
                    position: "sticky",
                    top: 0,
                    background: isDark ? "rgb(26, 27, 30)" : "white",
                }}
            >
                <Group position="apart" m="xs">
                    <UnstyledButton onClick={() => refreshStacks(false)}>
                        <IconRefresh color="rgb(34, 139, 230)" />
                    </UnstyledButton>
                    <Text>Deployments</Text>
                    <div>
                        {!account?.isTrial && (
                            <Button
                                size="compact-md"
                                onClick={analyzeAllHandler}
                                loading={analyzeAllLoading}
                            >
                                Analyze all
                            </Button>
                        )}
                    </div>
                </Group>
            </Title>
            <Table>
                <tbody>
                    {!data || data.length === 0 ? (
                        <tr>
                            <td> No deployments were found </td>
                        </tr>
                    ) : (
                        data.map((stack) => (
                            <AIStackRow
                                key={stack.id}
                                stack={stack}
                                isSelected={stack.id === selected?.id}
                                showDescription={showDescription}
                            />
                        ))
                    )}
                </tbody>
            </Table>
        </Paper>
    );
}

function AIStackIssueRow({
    issue,
    isSelected,
    setSelected,
}: {
    issue: IAIStackIssue;
    isSelected: boolean;
    setSelected: (index: number, selected: boolean) => void;
}) {
    return (
        <tr
            className={cx(styles.issue, { [styles.selected]: isSelected })}
            tabIndex={0}
            onClick={() => setSelected(issue.Index, !isSelected)}
            onKeyDown={(e) =>
                e.key === "Enter" ? setSelected(issue.Index, !isSelected) : ""
            }
        >
            <td>
                <Tooltip label={issue.Severity}>
                    <div className={styles[issue.Severity]}>
                        {isSelected ? <IconCheck /> : null}
                    </div>
                </Tooltip>
            </td>
            <td>
                <Text>
                    <b>{issue.LogicalName}</b>
                </Text>
                <Text lineClamp={3}>{issue.Issue}</Text>
                <Text c="dimmed" size="xs">
                    How to fix: {issue.HowToFix}
                </Text>
            </td>
        </tr>
    );
}

function AIStackIssuesView({
    stack,
    refreshStacks,
    makeFix,
    makeWontfix,
}: {
    stack: IAIStack;
    refreshStacks: (silent?: boolean) => Promise<void>;
    makeFix: (sel: number[]) => Promise<void>;
    makeWontfix: () => Promise<void>;
}) {
    const { loading, error, data, fetch } = useAIAnalysisIssues(stack);
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
    const [fixLoading, setFixLoading] = useState(false);
    const [wontfixLoading, setWontfixLoading] = useState(false);

    useEffect(() => {
        setSelectedIndexes([]);
        setFixLoading(false);
        setWontfixLoading(false);
    }, [data]);

    const issuesWasNotFound = !error && data && data.length === 0;

    const setSelected = (index: number, selected: boolean) => {
        setSelectedIndexes((prevIndexes) => {
            if (selected) {
                return prevIndexes.includes(index)
                    ? prevIndexes
                    : [...prevIndexes, index];
            } else {
                return prevIndexes.filter((i) => i !== index);
            }
        });
    };

    const wontFixHandler = async () => {
        setWontfixLoading(true);
        await makeWontfix();
        await refreshStacks(true);
        setWontfixLoading(false);
    };

    const fixHandler = async () => {
        setFixLoading(true);
        await makeFix(selectedIndexes);
        await refreshStacks(true);
        setFixLoading(false);
    };

    return (
        <div style={{ position: "relative", minHeight: "5rem" }}>
            <LoadingOverlay visible={loading} />
            {stack.state === StackAnalyzeState.Analyzed &&
            !loading &&
            !issuesWasNotFound ? (
                <Text pl="lg" pt="sm" pr="lg">
                    The AI has analysed the deployment and identified several
                    issues. Please review the list and select which issues you
                    would like the AI to fix, or discard them all with this
                    button{" "}
                    <Button
                        size="compact-md"
                        color="red"
                        onClick={wontFixHandler}
                        loading={wontfixLoading}
                        disabled={!!error || issuesWasNotFound || fixLoading}
                    >
                        Won't fix this template
                    </Button>
                </Text>
            ) : null}
            {stack.state === StackAnalyzeState.WontFix ? (
                <Text pl="lg" pt="sm" pr="lg">
                    <IconMoodCheck color="green" /> You marked this deployment
                    as 'Won't fix' and discarded all issues. If you have changed
                    your mind and would like to fix the issues, please select
                    the ones that you would like to fix.
                </Text>
            ) : null}
            <Group m="xs" mt="lg" spacing="xs">
                <UnstyledButton onClick={fetch}>
                    <IconRefresh color="rgb(34, 139, 230)" />
                </UnstyledButton>
                {(stack.state === StackAnalyzeState.Analyzed ||
                    stack.state === StackAnalyzeState.WontFix) &&
                !loading &&
                !issuesWasNotFound ? (
                    <Button
                        color="green"
                        onClick={fixHandler}
                        loading={fixLoading}
                        disabled={
                            !!error ||
                            selectedIndexes.length === 0 ||
                            wontfixLoading
                        }
                    >
                        Fix with AI
                    </Button>
                ) : null}
            </Group>
            {error ? (
                <Alert
                    icon={<IconAlertTriangle size="1rem" />}
                    title="Cannot get issues"
                    color="red"
                    mt="xs"
                >
                    {error}
                </Alert>
            ) : (
                !loading &&
                (issuesWasNotFound ? (
                    <Text m="xs">
                        <IconMoodCheck color="green" /> No issues were found by
                        AI
                    </Text>
                ) : (
                    <Table>
                        <tbody>
                            {data.map((issue) => (
                                <AIStackIssueRow
                                    key={issue.Index}
                                    issue={issue}
                                    isSelected={selectedIndexes.includes(
                                        issue.Index
                                    )}
                                    setSelected={setSelected}
                                />
                            ))}
                        </tbody>
                    </Table>
                ))
            )}
        </div>
    );
}

function AIStackChangesetView({
    stack,
    applyLoading,
    makeApply,
    makeToTemplateEdit,
}: {
    stack: IAIStack;
    applyLoading: boolean;
    makeApply: () => Promise<void>;
    makeToTemplateEdit: () => Promise<void>;
}) {
    const { loading, error, data } = useAIAnalysisChangeset(stack);
    const [toTemplateEditLoading, setToTemplateEditLoading] = useState(false);

    const toTemplateEditHandler = async () => {
        setToTemplateEditLoading(true);
        makeToTemplateEdit();
        setToTemplateEditLoading(false);
    };

    return (
        <div style={{ position: "relative" }}>
            <LoadingOverlay visible={loading} />
            <Text pl="lg" pr="lg">
                <p>
                    <IconMoodCheck color="darkorange" /> Changeset is ready for
                    apply. View changeset in AWS Console:{" "}
                    <Anchor href={data?.changeset_url} target="_blank">
                        <IconExternalLink size="1rem" />
                    </Anchor>
                    {". "}
                </p>
                <p>
                    To go back to edit the template or fix any other issues,
                    press this button{" "}
                    <Button
                        size="compact-md"
                        color="green"
                        disabled={!!error || applyLoading}
                        loading={toTemplateEditLoading}
                        onClick={toTemplateEditHandler}
                    >
                        To template edit
                    </Button>
                </p>
                <p>
                    To apply the template, press this button{" "}
                    <Button
                        size="compact-md"
                        color="red"
                        disabled={!!error || toTemplateEditLoading}
                        loading={applyLoading}
                        onClick={makeApply}
                    >
                        Apply
                    </Button>
                </p>
            </Text>
            {error ? (
                <Alert
                    icon={<IconAlertTriangle size="1rem" />}
                    title="Cannot get changeset from server"
                    color="red"
                    mt={"xs"}
                >
                    {error ?? "Data missing"}
                </Alert>
            ) : (
                <ScrollArea>
                    <Table>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Logical ID</th>
                                <th>Resource type</th>
                                <th>Replacement</th>
                                <th>Physical ID</th>
                                <th>Module</th>
                                <th>Hook invovations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.changeset
                                ? data.changeset.map((changeset, i) => (
                                      <tr key={i}>
                                          <td>
                                              {
                                                  changeset?.ResourceChange
                                                      ?.Action
                                              }
                                          </td>
                                          <td>
                                              {
                                                  changeset?.ResourceChange
                                                      ?.LogicalResourceId
                                              }
                                          </td>
                                          <td>
                                              {
                                                  changeset?.ResourceChange
                                                      ?.ResourceType
                                              }
                                          </td>
                                          <td>
                                              {
                                                  changeset?.ResourceChange
                                                      ?.Replacement
                                              }
                                          </td>
                                          <td>
                                              {
                                                  changeset?.ResourceChange
                                                      ?.PhysicalResourceId
                                              }
                                          </td>
                                          <td>
                                              {
                                                  changeset?.ResourceChange
                                                      ?.ModuleInfo
                                                      ?.LogicalIdHierarchy
                                              }{" "}
                                              {
                                                  changeset?.ResourceChange
                                                      ?.ModuleInfo
                                                      ?.TypeHierarchy
                                              }
                                          </td>
                                          <td>
                                              {changeset?.HookInvocationCount}
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </Table>
                </ScrollArea>
            )}
        </div>
    );
}

const helpContent = (
    <Text pl="lg" pr="lg">
        <p>
            First you need to analyze interesting deployments. To do this,
            select a deployment and click the{" "}
            <Button size="compact-md">Analyze</Button> button.
        </p>
        <p>
            When the analysis is complete, you can view the issues that the AI
            has identified for it.
        </p>
        <p>
            Then review the issues found and decide which ones you want to fix,
            select them and press the{" "}
            <Button size="compact-md" color="green">
                Fix with AI
            </Button>{" "}
            button.
        </p>
        <p>
            If you decide that the issues are not critical to you and you don't
            want to fix them, press the{" "}
            <Button size="compact-md" color="red">
                Won't fix
            </Button>{" "}
            button.
        </p>
    </Text>
);

function AIStackDetail({
    stack,
    refreshStacks,
    analyze,
    wontfix,
    fix,
    fixanotherissues,
    totemplateedit,
    reset,
    apply,
    backButton,
}: {
    stack?: IAIStack;
    refreshStacks: (silent?: boolean) => Promise<void>;
    analyze: (id: number) => Promise<void>;
    wontfix: (id: number) => Promise<void>;
    fix: (id: number, sel: number[]) => Promise<void>;
    fixanotherissues: (id: number) => Promise<void>;
    totemplateedit: (id: number) => Promise<void>;
    reset: (id: number) => Promise<void>;
    apply: (id: number) => Promise<void>;
    backButton?: ReactNode;
}) {
    var content = null;
    const { account } = useContext(InitSettingsContext);
    const navigate = useNavigate();
    const [analyzeLoading, setAnalyzeLoading] = useState(false);
    const [fixanotherissuesLoading, setFixanotherissuesLoading] =
        useState(false);
    const [applyLoading, setApplyLoading] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);

    useEffect(() => {
        setAnalyzeLoading(false);
        setApplyLoading(false);
        setResetLoading(false);
    }, [stack?.id])

    if (!stack) {
        content = helpContent;
    } else {
        const makeAnalyze = async () => {
            try {
                await analyze(stack.id);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot analyze deployment "${stack.name}"`,
                    e as AxiosError
                );
            }
        };

        const makeWontfix = async () => {
            try {
                await wontfix(stack.id);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot set deployment "${stack.name}" analysis status to "Won't fix"`,
                    e as AxiosError
                );
            }
        };

        const makeFix = async (sel: number[]) => {
            try {
                await fix(stack.id, sel);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot fix deployment "${stack.name}"`,
                    e as AxiosError
                );
            }
        };

        const makeFixanotherissues = async () => {
            try {
                await fixanotherissues(stack.id);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot reset deployment "${stack.name}" analysis status to fix another issue`,
                    e as AxiosError
                );
            }
        };

        const makeReset = async () => {
            try {
                await reset(stack.id);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot reset the deployment "${stack.name} analysis status"`,
                    e as AxiosError
                );
            }
        };

        const makeToTemplateEdit = async () => {
            try {
                await totemplateedit(stack.id);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot set the deployment "${stack.name} analysis status"`,
                    e as AxiosError
                );
            }
        };

        const makeApply = async () => {
            modals.openConfirmModal({
                title: "Apply a changeset",
                centered: true,
                children: (
                    <Text size="sm">
                        Are you sure you want to apply a deployment changeset in
                        your cloud?
                    </Text>
                ),
                labels: {
                    confirm: "Apply a changeset",
                    cancel: "No, don't do it",
                },
                confirmProps: { color: "red" },
                onConfirm: async () => {
                    setApplyLoading(true);
                    try {
                        await apply(stack.id);
                    } catch (e: unknown) {
                        ShowModalError(
                            `Unable to apply the changeset of the "${stack.name}" deployment.`,
                            e as AxiosError
                        );
                    }
                    refreshStacks(true);
                    setApplyLoading(false);
                },
            });
        };

        // buttons handlers

        const analyzeHandler = async () => {
            setAnalyzeLoading(true);
            await makeAnalyze();
            await refreshStacks(true);
            setAnalyzeLoading(false);
        };

        const fixanotherissuesHandler = async () => {
            setFixanotherissuesLoading(true);
            await makeFixanotherissues();
            await refreshStacks(true);
            setFixanotherissuesLoading(false);
        };

        const resetHandler = async () => {
            setResetLoading(true);
            await makeReset();
            await refreshStacks(true);
            setResetLoading(false);
        };

        const commonInfo = (
            <Text pl="lg" pr="lg">
                {backButton}
                <Text c="dimmed">{stack.cloud_name}</Text>
                <Title order={3}>{stack.name}</Title>
                <Text c="dimmed">{stack.description}</Text>
            </Text>
        );

        let stackInfo = <></>;
        switch (stack.state) {
            case StackAnalyzeState.NotAnalyzed:
                if (account && account?.isTrial && stack.isBigForTrial) {
                    stackInfo = (
                        <Text p="lg">
                            This deployment is too large for Trial.
                            <br />
                            To analyze deployment with AI, subscribe to Oasis
                            Defender.
                            <br />
                            <Button onClick={() => navigate("/account")}>
                                To personal portal
                            </Button>
                        </Text>
                    );
                } else {
                    stackInfo = (
                        <Text p="lg">
                            To analyze the deployment with AI click the button{" "}
                            <Button
                                size="compact-md"
                                onClick={analyzeHandler}
                                loading={analyzeLoading}
                            >
                                Analyze
                            </Button>
                        </Text>
                    );
                }
                break;
            case StackAnalyzeState.AnalyzeInProgress:
            case StackAnalyzeState.FixInProgress:
            case StackAnalyzeState.ApplyInProgress:
            case StackAnalyzeState.ChangesetInProgress:
                stackInfo = (
                    <Center p="lg">
                        <i>
                            {stack.state ===
                                StackAnalyzeState.AnalyzeInProgress &&
                                "AI is analysing the deployment"}
                            {stack.state === StackAnalyzeState.FixInProgress &&
                                "AI is preparing a deployment fix"}
                            {stack.state ===
                                StackAnalyzeState.ApplyInProgress &&
                                "Deployment fix in progress"}
                            {stack.state ===
                                StackAnalyzeState.ChangesetInProgress &&
                                "Changeset creation in progress"}
                        </i>
                        <AutoRefreshLoader refresh={refreshStacks} />
                    </Center>
                );
                break;
            case StackAnalyzeState.Analyzed:
            case StackAnalyzeState.WontFix:
                stackInfo = (
                    <AIStackIssuesView
                        stack={stack}
                        refreshStacks={refreshStacks}
                        makeFix={makeFix}
                        makeWontfix={makeWontfix}
                    />
                );
                break;
            case StackAnalyzeState.FixReady:
                stackInfo = (
                    <Text pl="lg" pr="lg" pt="lg">
                        <IconMoodCheck color="orange" /> Fixed template is ready
                        for deployment.
                        <p>
                            To compare the AI fixed deployment template with the
                            original and apply fix, click the button{" "}
                            <Link to={`/ai/stack/${stack.id}/compare`}>
                                <Button color="green" size="compact-md">
                                    Compare templates
                                </Button>
                            </Link>
                        </p>
                        <p>
                            To reset the deployment fix status, click the button{" "}
                            <Button
                                size="compact-md"
                                loading={fixanotherissuesLoading}
                                onClick={fixanotherissuesHandler}
                            >
                                Fix another isuues
                            </Button>
                        </p>
                    </Text>
                );
                break;
            case StackAnalyzeState.ChangesetReady:
                stackInfo = (
                    <AIStackChangesetView
                        stack={stack}
                        applyLoading={applyLoading}
                        makeApply={makeApply}
                        makeToTemplateEdit={makeToTemplateEdit}
                    />
                );
                break;
            case StackAnalyzeState.Error:
            case StackAnalyzeState.ErrorAnalyze:
            case StackAnalyzeState.ErrorFix:
            case StackAnalyzeState.ErrorChangeset:
            case StackAnalyzeState.ErrorApply:
                stackInfo = (
                    <Text pl="lg" pr="lg" pt="lg">
                        Deployment analysis is in error state:
                        <Text color="red">
                            {stack.error_text ?? "Error text missing"}
                        </Text>
                        <p>
                            To reset the deployment analysis status click the
                            button{" "}
                            <Button
                                size="compact-md"
                                loading={resetLoading}
                                onClick={resetHandler}
                            >
                                Reset
                            </Button>
                        </p>
                    </Text>
                );
                break;
        }

        content = (
            <Text>
                {commonInfo}
                {stackInfo}
            </Text>
        );
    }

    return (
        <Paper
            shadow="md"
            radius="xl"
            withBorder
            mt="sm"
            mb="sm"
            mr="sm"
            pt="xl"
            pb="2rem"
        >
            {content}
        </Paper>
    );
}

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function AIAnalysis() {
    const navigate = useNavigate();
    const dualsideViewQuery = useMediaQuery("(min-width: 80em)");
    const showStackDescriptionQuery = useMediaQuery("(min-width: 40em)");

    const {
        loading,
        data,
        error,
        fetch: refreshStacks,
        analyze,
        wontfix,
        fix,
        fixanotherissues,
        totemplateedit,
        reset,
        apply,
        analyzeAll,
    } = useAIAnalysis();
    const [selected, setSelected] = useState<IAIStack | undefined>();

    const selectedRef = useRef(selected);
    selectedRef.current = selected;

    let { id } = useParams();
    const prevId = usePrevious(id);

    useEffect(() => {
        if (data) {
            if (id === undefined) {
                setSelected(undefined);
            } else {
                const newElem = data.filter((el) => el.id === Number(id));
                if (newElem.length === 0) {
                    navigate("/ai");
                } else {
                    setSelected(newElem[0]);
                }
            }
        } else {
            setSelected(undefined);
        }
    }, [data, id, navigate]);

    useEffect(() => {
        if (!dualsideViewQuery && id === undefined && prevId) {
            const timer = setTimeout(() => {
                const selector = `.${styles.stack}[data-id="${prevId}"]`;
                const element = document.querySelector(selector);
                if (element) {
                    element.scrollIntoView({
                        behavior: "auto",
                        block: "nearest",
                    });
                }
            }, 0);
            return () => clearTimeout(timer);
        }
    }, [id]);

    const { demoStateCurrent } = useContext(InitSettingsContext);
    useEffectAfterMount(() => {
        navigate("/ai");
        refreshStacks(false);
    }, [demoStateCurrent]);

    var content = null;
    if (error || !data) {
        content = (
            <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Cannot get deployments"
                color="red"
                mt={"xs"}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                {error ?? "Data missing"}
            </Alert>
        );
    } else {
        const stacksView = (
            <AIStacksView
                data={data}
                selected={selected}
                setSelected={setSelected}
                refreshStacks={refreshStacks}
                analyzeAll={analyzeAll}
                showDescription={showStackDescriptionQuery}
            />
        );

        const stackDetails = (
            <AIStackDetail
                stack={selected}
                refreshStacks={refreshStacks}
                analyze={analyze}
                wontfix={wontfix}
                fixanotherissues={fixanotherissues}
                totemplateedit={totemplateedit}
                fix={fix}
                reset={reset}
                apply={apply}
                backButton={
                    dualsideViewQuery ? undefined : (
                        <Button
                            size="compact-md"
                            mb="md"
                            leftIcon={<IconArrowBack />}
                            onClick={() => navigate("/ai")}
                        >
                            To deployments
                        </Button>
                    )
                }
            />
        );
        content = dualsideViewQuery ? (
            <Container size="120rem" h="100%" mih="30rem">
                <Grid h="100%" m={0}>
                    <Grid.Col span={5} h="100%" p={0}>
                        <ScrollArea h="100%">{stacksView}</ScrollArea>
                    </Grid.Col>
                    <Grid.Col span={7} h="100%" p={0}>
                        <ScrollArea h="100%">{stackDetails}</ScrollArea>
                    </Grid.Col>
                </Grid>
            </Container>
        ) : (
            <Container>{selected ? stackDetails : stacksView}</Container>
        );
    }

    return (
        <div
            style={
                dualsideViewQuery
                    ? {
                          width: "100vw",
                          height: `calc(100vh - ${HEADER_HEIGHT})`,
                          position: "relative",
                      }
                    : undefined
            }
        >
            {content}
            <LoadingOverlay
                visible={loading}
                transitionDuration={200}
                overlayBlur={100}
            />
        </div>
    );
}
