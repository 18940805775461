import React, { useContext, useRef, useState } from "react";

import {
    Alert,
    Button,
    Container,
    Group,
    LoadingOverlay,
    Modal,
    ScrollArea,
    SegmentedControl,
    Space,
    Spoiler,
    Table,
    Text,
} from "@mantine/core";
import {
    IconAlertTriangle,
    IconCloudPlus,
    IconRefresh,
} from "@tabler/icons-react";

import { ICloudAdd, ICloudTest, ICloudView } from "../core/models/ICloud";
import { useClouds } from "../core/hooks/clouds";
import { CloudTableRow } from "../components/CloudTableRow";
import { useDisclosure } from "@mantine/hooks";
import { AddEditCloudForm } from "../components/AddEditCloudForm";
import { ShowModalError } from "../core/oasiserror";
import { AxiosError } from "axios";
import { modals } from "@mantine/modals";
import { useEffectAfterMount } from "../core/hooks/useEffectAfterMount";
import InitSettingsContext from "../core/initsettings/InitSettingsContext";

export function Clouds() {
    const {
        loading: cloudsLoading,
        error: cloudsError,
        clouds,
        fetchClouds,
        syncCloud,
        deleteCloud,
        addCloud,
        editCloud,
        testCloud,
    } = useClouds();

    const [
        addEditCloudModalOpened,
        { open: openAddEditCloudModal, close: closeAddEditCloudModal },
    ] = useDisclosure(false);
    const [moreInfoShowed, setMoreInfoShowed] = useState<string>("compact");
    const [addEditCloudInProgress, setAddEditCloudInProgress] = useState(false);
    const closeAddEditCloudModalIfNotInProgress = () => {
        if (!addEditCloudInProgress) {
            closeAddEditCloudModal();
        }
    };
    const currentCloudRef = useRef<ICloudView>();

    const { demoStateCurrent } = useContext(InitSettingsContext);
    useEffectAfterMount(() => {
        fetchClouds(false);
    }, [demoStateCurrent]);

    const makeRefresh = async (silent?: boolean) => {
        await fetchClouds(silent);
    };

    const makeSync = async (cloud: ICloudView) => {
        try {
            await syncCloud(cloud.id);
        } catch (e: unknown) {
            ShowModalError(
                `Cannot sync cloud "${cloud.name}"`,
                e as AxiosError
            );
        }
    };

    const makeTest = async (name: string, cloud: ICloudTest) => {
        try {
            const result = await testCloud(cloud);
            modals.open({
                title: `Cloud "${name}": connection test`,
                centered: true,
                onClose: () => {},
                children: result.parameters.map((p, i) => {
                    const res = <span> {p.isGood ? "✅" : "⛔️"} </span>;
                    return (
                        <Text mt={"xs"} key={i}>
                            {res} {p.title} <br />
                            {p.msg && (
                                <Spoiler
                                    maxHeight={0}
                                    hideLabel="Hide"
                                    showLabel="Show error"
                                >
                                    {p.msg}
                                </Spoiler>
                            )}
                        </Text>
                    );
                }),
            });
        } catch (e: unknown) {
            ShowModalError(`Cannot test cloud`, e as AxiosError);
        }
    };

    const makeDelete = async (cloud: ICloudView) => {
        try {
            await deleteCloud(cloud.id);
        } catch (e: unknown) {
            ShowModalError(
                `Cannot delete cloud "${cloud.name}"`,
                e as AxiosError
            );
        }
    };

    const makeAddCloud = async (cloud: ICloudAdd) => {
        setAddEditCloudInProgress(true);
        try {
            await addCloud(cloud);
            setAddEditCloudInProgress(false);
            closeAddEditCloudModal();
        } catch (e: unknown) {
            setAddEditCloudInProgress(false);
            ShowModalError(`Error while adding cloud`, e as AxiosError);
        }
    };

    const makeEditCloud = async (id: number, cloud: ICloudAdd) => {
        setAddEditCloudInProgress(true);
        try {
            await editCloud(id, cloud);
            setAddEditCloudInProgress(false);
            closeAddEditCloudModal();
        } catch (e: unknown) {
            setAddEditCloudInProgress(false);
            ShowModalError(`Error while editing cloud`, e as AxiosError);
        }
    };

    const addCloudClickHandler = () => {
        currentCloudRef.current = undefined;
        openAddEditCloudModal();
    };

    const editCloudClickHandler = (cloud: ICloudView) => {
        currentCloudRef.current = cloud;
        openAddEditCloudModal();
    };

    const rows = clouds.map((cloud) => (
        <CloudTableRow
            key={cloud.id}
            cloud={cloud}
            edit={editCloudClickHandler}
            makeTest={makeTest}
            makeSync={makeSync}
            makeDelete={makeDelete}
            makeRefresh={makeRefresh}
            moreInfoShowed={moreInfoShowed === "full"}
        />
    ));

    return (
        <>
            <Container>
                <Space h="sm" />

                <Group position="apart">
                    <Group>
                        <Button
                            leftIcon={<IconRefresh size="1.125rem" />}
                            loading={cloudsLoading}
                            onClick={() => makeRefresh(false)}
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<IconCloudPlus size="1.125rem" />}
                            onClick={addCloudClickHandler}
                        >
                            Add Cloud
                        </Button>
                    </Group>
                    <Group>
                        <SegmentedControl
                            value={moreInfoShowed}
                            onChange={setMoreInfoShowed}
                            color="yellow"
                            data={[
                                {
                                    label: "Compact",
                                    value: "compact",                                    
                                },
                                {
                                    label: "Full",
                                    value: "full",
                                },
                            ]}
                        />
                    </Group>
                </Group>
            </Container>

            <ScrollArea>
                <Container size={moreInfoShowed === "full" ? "xl" : "md"}>
                    {!cloudsLoading &&
                        cloudsError == null &&
                        clouds.length > 0 && (
                            <Table highlightOnHover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        {(moreInfoShowed === "full") && <th>Details</th>}
                                        <th>Sync</th>
                                        <th style={{ textAlign: "center" }}>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        )}

                    {!cloudsLoading &&
                        cloudsError == null &&
                        clouds.length === 0 && (
                            <Text p={"xs"}>
                                You have not added any clouds. Please add at
                                least one cloud.
                                <br />
                                You can also enable Demo mode to see Oasis
                                Defender functionality on demo clouds.
                            </Text>
                        )}

                    {!cloudsLoading && cloudsError != null && (
                        <Alert
                            icon={<IconAlertTriangle size="1rem" />}
                            title="Cannot get clouds"
                            color="red"
                            mt={"xs"}
                        >
                            {cloudsError}
                        </Alert>
                    )}
                </Container>
            </ScrollArea>

            <Modal
                opened={addEditCloudModalOpened}
                onClose={closeAddEditCloudModalIfNotInProgress}
                title="Cloud access data"
                centered
            >
                <LoadingOverlay visible={addEditCloudInProgress} />
                <AddEditCloudForm
                    currentCloudRef={currentCloudRef}
                    makeTest={makeTest}
                    makeAddCloud={makeAddCloud}
                    makeEditCloud={makeEditCloud}
                    onCancel={closeAddEditCloudModalIfNotInProgress}
                />
            </Modal>

            {global.config.version && (
                <div
                    id="version"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: "10px",
                        color: "#80808060",
                    }}
                >
                    {global.config.version}
                </div>
            )}
        </>
    );
}
