import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {
    ColorScheme,
    ColorSchemeProvider,
    MantineProvider,
} from "@mantine/core";


import { HeaderResponsive as Header } from "./components/Header";

import { Clouds } from "./pages/Clouds";
import { CloudMap } from "./pages/CloudMap";
import { ModalsProvider } from "@mantine/modals";
import { PolicyMap } from "./pages/PolicyMap";
import { StoragesMap } from "./pages/StoragesMap";
import { Analyze } from "./pages/Analyze";
import { severityToColor } from "./core/severity";
import { IconCircle } from "@tabler/icons-react";
import { useHeaderInfo } from "./core/hooks/headerInfo";
import { useInterval, useLocalStorage } from "@mantine/hooks";
import { AnalyzeView } from "./pages/AnalyzeView";
import { Account } from "./components/Account";
import { GlobalMessage } from "./components/GlobalMessage";
import InitSettingsContext from "./core/initsettings/InitSettingsContext";
import { IntercomProvider } from "react-use-intercom";
import { AIAnalysis } from "./pages/AIAnalysis";
import AICompareTemplates from "./components/AICompareTemplates";

interface AppProps {
    sub?: string;
    username?: string;
}

function App({ sub, username }: AppProps) {
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: "color-scheme",
        defaultValue: "light",
        getInitialValueInEffect: true,
    });
    const toggleColorScheme = (value?: ColorScheme) => {
        setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
    };
    const { fetch, maxSeverity } = useHeaderInfo();
    const headerInfoUpdate = useInterval(fetch, 60000);
    const { intercomSettings, ai } = useContext(InitSettingsContext);

    useEffect(() => {
        console.log("App started");

        const defaultStyle = document.body.style.background;
        return () => {
            document.body.style.background = defaultStyle;
        };
    }, []);
    useEffect(() => {
        document.body.style.background =
            colorScheme === "dark" ? "#343A40" : "#F5F5DC";
    }, [colorScheme]);

    useEffect(() => {
        if (ReactGA.isInitialized) {            
            ReactGA.set({ "userId": sub });
            ReactGA.event({ category: "auth", action: "login" });
        }
    }, [username, sub]);

    useEffect(() => {
        headerInfoUpdate.start();
        return () => {
            headerInfoUpdate.stop();
        };
    }, [headerInfoUpdate]);

    const headerLinks = [
        {
            link: "/clouds",
            label: "My Clouds",
        },
        ...(ai
            ? [
                  {
                      link: "/ai",
                      label: "AI Analysis",
                      isActiveFn: (path: string): boolean =>
                          path === "/ai" || path.startsWith("/ai/"),
                  },
              ]
            : []),
        {
            link: "/map",
            label: "Cloud Map",
        },
        {
            link: "/storages",
            label: "Storages",
        },
        {
            link: "/policy",
            label: "Policy Map",
        },
        {
            link: "/analyze",
            label: (
                <>
                    {maxSeverity !== undefined && (
                        <IconCircle
                            size="12px"
                            stroke="0.05rem"
                            fill={severityToColor(maxSeverity)}
                        />
                    )}
                    {" Security Analysis"}
                </>
            ),
            children: [
                { link: "/analyze", label: "Findings" },
                {
                    link: "/analyze-view/resultsvisualisation1",
                    label: "Security Group Issues",
                },
                {
                    link: "/analyze-view/resultsvisualisation2",
                    label: "Subnet NACL Issues",
                },
            ],
            isActiveFn: (path: string): boolean =>
                path === "/analyze" || path.startsWith("/analyze-view/"),
        },
    ];

    const router = (
        <Router>
            <ColorSchemeProvider
                colorScheme={colorScheme}
                toggleColorScheme={toggleColorScheme}
            >
                <MantineProvider
                    theme={{ colorScheme }}
                    withGlobalStyles
                    withNormalizeCSS
                >
                    <ModalsProvider>
                        <Header links={headerLinks} username={username} />
                        <Routes>
                            <Route path="/clouds" element={<Clouds />} />
                            {ai && (
                                <>
                                    <Route
                                        path="/ai"
                                        element={<AIAnalysis />}
                                    />
                                    <Route
                                        path="/ai/stack/:id"
                                        element={<AIAnalysis />}
                                    />
                                    <Route
                                        path="/ai/stack/:id/compare"
                                        element={<AICompareTemplates />}
                                    />
                                </>
                            )}
                            <Route path="/map" element={<CloudMap />} />
                            <Route path="/storages" element={<StoragesMap />} />
                            <Route path="/policy" element={<PolicyMap />} />
                            <Route path="/analyze" element={<Analyze />} />
                            <Route
                                path="/analyze-view/:viewName"
                                element={<AnalyzeView />}
                            />
                            <Route path="/account" element={<Account />} />
                            <Route
                                path="/"
                                element={<Navigate replace to="/clouds" />}
                            />
                        </Routes>
                        <GlobalMessage />
                    </ModalsProvider>
                </MantineProvider>
            </ColorSchemeProvider>
        </Router>
    );

    if (intercomSettings && intercomSettings?.appID) {
        return (
            <IntercomProvider
                appId={intercomSettings?.appID}
                apiBase={intercomSettings.apiBase}
                autoBootProps={{
                    email: intercomSettings.email,
                    userHash: intercomSettings.userHash,
                }}
                autoBoot
            >
                {router}
            </IntercomProvider>
        );
    }

    return router;
}

export default App;
