import { Loader } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useEffect } from "react";

export function AutoRefreshLoader({
    refresh,
}: {
    refresh: (silent?: boolean) => void;
}) {
    const refreshInterval = useInterval(() => refresh(true), 5000);

    useEffect(() => {
        refreshInterval.start();
        return () => {
            refreshInterval.stop();
        };
    }, [refreshInterval]);

    return <Loader ml="md" />;
}
