import React from "react";
import {
    ScrollArea,
    Group,
    Tooltip,
    Paper,
    useMantineTheme,
} from "@mantine/core";

export interface MapMenuItem {
    id: string | number;
    label: React.ReactNode;
    description?: string;
    icon?: React.ReactNode;
    isActive?: boolean;
    isVisible?: boolean;
    onClick?: () => void;
}

interface MapMenuProps {
    menuItems: MapMenuItem[];
}

const MapMenu: React.FC<MapMenuProps> = ({ menuItems }) => {
    const theme = useMantineTheme();

    return (
        <ScrollArea>
            <Group position="center" noWrap p="xs">
                {menuItems
                    .filter((item) => item.isVisible !== false)
                    .map((item) => (
                        <Tooltip
                            label={item.description ?? item.label ?? item.id}
                            key={item.id}
                            withinPortal
                            withArrow
                        >
                            <Paper
                                pl="0.5rem"
                                pr="0.5rem"
                                pt="0.5rem"
                                pb="0.5rem"
                                radius="lg"
                                withBorder
                                onClick={item.onClick}
                                sx={{
                                    textWrap: "nowrap",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    backgroundColor: item.isActive
                                        ? theme.colors.blue[5]
                                        : undefined,
                                    color: item.isActive ? "white" : undefined,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                    lineHeight: 1
                                }}
                            >
                                {item.icon}
                                {item.label}
                            </Paper>
                        </Tooltip>
                    ))}
            </Group>
        </ScrollArea>
    );
};

export default MapMenu;
