export enum StackAnalyzeState {
    NotAnalyzed = 0, // Not analyzed yet
    AnalyzeInProgress = 1, // Analyze in progress
    Analyzed = 2, // Analyze ready
    FixInProgress = 3, // Preparing fix in ChatGPT
    FixReady = 4, // New template ready
    ChangesetInProgress = 5, // Applying new template
    ChangesetReady = 6, // Changeset is ready
    ApplyInProgress = 7, // Applying new template
    WontFix = 8, // User doesn't want to fix errors
    Error = 100, // Error in some state (Error text in 'error_text')
    ErrorAnalyze = 101, // Error in AnalyzeInProgress state (Error text in 'error_text')
    ErrorFix = 102, // Error in FixInProgress state (Error text in 'error_text')
    ErrorChangeset = 103, // Error in ChangesetInProgress state (Error text in 'error_text')
    ErrorApply = 104, // Error in ApplyInProgress state (Error text in 'error_text')
}

export type IAIIssueCount = {
    high: number; // High issues count
    medium: number; // Medium issues count
    low: number; // Low issues count
};

export interface IAIStack {
    id: number; // Stack (Deployment etc) ID
    type?: string; // Type
    state: StackAnalyzeState; // Stack state
    name: string; // Stack name
    description?: string; // Stack description
    cloud_id: number; // Cloud ID
    cloud_name: string; // Cloud name
    issues?: IAIIssueCount; // null if not analyzed, must be in 'Analyzed' state
    error_text?: string; // null if no error, must be in 'Error' state
    isBigForTrial?: boolean;
}

export interface IAIStackIssue {
    Index: number;
    LogicalName: string;
    Severity: "High" | "Medium" | "Low";
    Issue: string;
    HowToFix: string;
}

export interface IAIStackIssueContent {
    Issues: IAIStackIssue[];
}

interface IAITarget {
    Attribute?:
        | "Properties"
        | "Metadata"
        | "CreationPolicy"
        | "UpdatePolicy"
        | "DeletionPolicy"
        | "UpdateReplacePolicy"
        | "Tags";
    Name?: string;
    RequiresRecreation?: "Never" | "Conditionally" | "Always";
}

interface IAIResourceChangeDetail {
    Target?: IAITarget;
    Evaluation?: "Static" | "Dynamic";
    ChangeSource?:
        | "ResourceReference"
        | "ParameterReference"
        | "ResourceAttribute"
        | "DirectModification"
        | "Automatic";
    CausingEntity?: string;
}

interface IAIModuleInfo {
    TypeHierarchy?: string;
    LogicalIdHierarchy?: string;
}

interface IAIResourceChange {
    Action?: "Add" | "Modify" | "Remove" | "Import" | "Dynamic";
    LogicalResourceId?: string;
    PhysicalResourceId?: string;
    ResourceType?: string;
    Replacement?: "True" | "False" | "Conditional";
    Scope?: (
        | "Properties"
        | "Metadata"
        | "CreationPolicy"
        | "UpdatePolicy"
        | "DeletionPolicy"
        | "UpdateReplacePolicy"
        | "Tags"
    )[];
    Details?: IAIResourceChangeDetail[];
    ChangeSetId?: string;
    ModuleInfo?: IAIModuleInfo;
}

interface IAIResourceConfiguration {
    Type?: "Resource";
    HookInvocationCount?: number;
    ResourceChange?: IAIResourceChange;
}

export interface IAIStackChangeset {
    changeset_url: string;
    changeset: IAIResourceConfiguration[];
}

export interface IAIStackCompareDiffResultItem {
    issue: {
        LogicalName: string,
        Issue: string
    },
    what: string;
    how: string;
    template?: {
        start_line: number;
        stop_line: number;
        orig: string;
        new: string;
    } | null,
    cli?: string[] | null;
}

export interface IAIStackCompareDiff extends IAIStack {
    current_template: string; // Stack template
    ai_template: string; // Original AI fixed template without human fix
    human_template: string; // Final fixed template
    ai_fix_result: IAIStackCompareDiffResultItem[];
}
