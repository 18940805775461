import {
    Button,
    Container,
    Text,
    Anchor,
    Card,
    Center,
    Stack,
    Loader,
    Alert,
    Modal,
    Group,
    PasswordInput,
    LoadingOverlay,
    useMantineColorScheme
} from "@mantine/core";

import { Auth } from "aws-amplify";
import { useAccount } from "../core/hooks/account";
import { IconAlertTriangle, IconMoonStars, IconSun } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useContext, useState } from "react";
import { ShowModalError } from "../core/oasiserror";
import InitSettingsContext from "../core/initsettings/InitSettingsContext";

function convertSecondsToDays(seconds?: number): string {
    if (seconds === undefined) return "?";

    if (seconds <= 0) {
        return "Expired";
    }

    const days = Math.floor(seconds / (3600 * 24)) + 1;

    if (days > 1) {
        return `${days} days left`;
    }
    return "1 day left";
}

function accountProviderToString(p: string): string {
    if (p === "DIGITALOCEAN") return "DigitalOcean";
    return p;
}

type PasswordChangeData = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
};

function ChangePasswordModal({
    opened,
    setOpened,
}: {
    opened: boolean;
    setOpened: (opened: boolean) => void;
}) {
    const [loading, setLoading] = useState(false);
    const form = useForm<PasswordChangeData>({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validate: {
            oldPassword: (value) =>
                value.length < 2 ? "Fill old password" : null,
            newPassword: (value) =>
                value.length < 2 ? "Fill new password" : null,
            confirmPassword: (value, values) =>
                value === values.newPassword ? null : "Passwords do NOT match",
        },
    });

    const closeDialog = () => {
        setOpened(false);
        form.reset();
    };

    const changePassword = async (values: PasswordChangeData) => {
        try {
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            const data = await Auth.changePassword(
                user,
                values.oldPassword,
                values.newPassword
            );
            setLoading(false);
            console.log(data);
            closeDialog();
        } catch (err: unknown) {
            setLoading(false);
            console.log(err);
            ShowModalError("Change password error", err);
        }
    };

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Modal
                opened={opened}
                onClose={closeDialog}
                title="Change your password"
                centered
            >
                <form onSubmit={form.onSubmit(changePassword)}>
                    <PasswordInput
                        withAsterisk
                        label="Old Password"
                        placeholder=""
                        {...form.getInputProps("oldPassword")}
                    />
                    <PasswordInput
                        withAsterisk
                        label="New Password"
                        placeholder=""
                        {...form.getInputProps("newPassword")}
                    />
                    <PasswordInput
                        withAsterisk
                        label="Confirm Password"
                        placeholder=""
                        {...form.getInputProps("confirmPassword")}
                    />
                    <Group position="right" mt="md">
                        <Button onClick={closeDialog} variant="default">
                            Cancel
                        </Button>
                        <Button color="blue" type="submit">
                            Change Password
                        </Button>
                    </Group>
                </form>
            </Modal>
        </>
    );
}

export function Account() {
    const { account: settingsAccount } = useContext(InitSettingsContext);
    let { loading, error, data } = useAccount();
    const [changePasswordOpened, setChangePasswordOpened] = useState(false);
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const dark = colorScheme === "dark";

    if (loading) {
        return (
            <Loader
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
        );
    }

    if (error) {
        return (
            <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Cannot get information"
                color="red"
                mt={"xs"}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                {error}
            </Alert>
        );
    }

    if (!data) {
        return <Text>Empty data</Text>;
    }

    const modals: React.ReactElement[] = [];
    const cards: React.ReactElement[] = [];

    cards.push(
        <Card withBorder shadow="sm" radius="md" mt="xs">
            <Card.Section withBorder inheritPadding py="xs">
                <Text fw={500}>Appearance</Text>
            </Card.Section>
            <Center mt="xs">
                <Button
                    variant="filled"
                    color={dark ? "yellow" : "blue"}
                    onClick={() => toggleColorScheme()}
                    leftIcon={
                        dark ? (
                            <IconSun />
                        ) : (
                            <IconMoonStars />
                        )
                    }
                >
                    {dark
                        ? "Switch to the light theme"
                        : "Switch to the dark theme"}
                </Button>
            </Center>
        </Card>
    );

    if (data.is_onpremise) {
        // On-premise version
        cards.push(
            <Card withBorder shadow="sm" radius="md" mt="xs">
                <Card.Section withBorder inheritPadding py="xs">
                    <Text fw={500}>On-premise</Text>
                </Card.Section>
                <Text mt="sm" size="sm">
                    Oasis Defender is running in on-premise mode
                </Text>
            </Card>
        );
    } else {
        // SaaS
        const daysLeft =
            (data.was_subscription === false
                ? data.trial_left
                : data.subscription_left) ?? 0;

        modals.push(
            <ChangePasswordModal
                opened={changePasswordOpened}
                setOpened={setChangePasswordOpened}
            />
        );

        cards.push(
            <Card withBorder shadow="sm" radius="md" mt="xs">
                <Card.Section withBorder inheritPadding py="xs">
                    <Text fw={500}>Account</Text>
                </Card.Section>
                <Text mt="sm" size="sm">
                    <b>email:</b> {data.email}
                </Text>
                <Center mt="sm">
                    <Stack justify="space-around" spacing="xs">
                        {!settingsAccount?.accountProvider && (
                            <Button
                                variant="filled"
                                onClick={() => {
                                    setChangePasswordOpened(true);
                                }}
                            >
                                Change password
                            </Button>
                        )}
                        <Button
                            variant="filled"
                            color="red"
                            onClick={() => Auth.signOut()}
                        >
                            Sign out
                        </Button>
                    </Stack>
                </Center>
            </Card>
        );

        cards.push(
            <Card withBorder shadow="sm" radius="md" mt="xs">
                <Card.Section withBorder inheritPadding py="xs">
                    <Text fw={500}>
                        {data.was_subscription === false
                            ? "Trial"
                            : "Subscription"}{" "}
                    </Text>
                </Card.Section>
                {settingsAccount?.accountProvider ? (
                    <Text mt="sm" size="sm">
                        Your subscription is managed by{" "}
                        {accountProviderToString(
                            settingsAccount?.accountProvider
                        )}
                    </Text>
                ) : (
                    <>
                        <Text mt="sm" size="sm">
                            {convertSecondsToDays(daysLeft)}
                        </Text>
                        <Center mt="sm">
                            <Stack justify="space-around" spacing="xs">
                                <Button
                                    variant="filled"
                                    component="a"
                                    href={
                                        data.was_subscription === false ||
                                        daysLeft <= 0
                                            ? data.new_subscription_url
                                            : data.manage_subscription_url
                                    }
                                >
                                    {data.was_subscription === false ||
                                    daysLeft <= 0
                                        ? "Subscribe"
                                        : "Manage subscription"}
                                </Button>
                            </Stack>
                        </Center>
                    </>
                )}
            </Card>
        );

        cards.push(
            <Card withBorder shadow="sm" radius="md" mt="xs">
                <Card.Section withBorder inheritPadding py="xs">
                    <Text fw={500}>Terms and Policies</Text>
                </Card.Section>
                <Stack spacing="sm" pt="sm">
                    <Anchor href="/static/terms.html" target="_blank">
                        Terms of Service
                    </Anchor>
                    <Anchor href="/static/cookies.html" target="_blank">
                        Cookie Policy
                    </Anchor>
                    <Anchor href="/static/privacy.html" target="_blank">
                        Privacy Policy
                    </Anchor>
                    <Anchor href="/static/disclaimer.html" target="_blank">
                        Disclaimer
                    </Anchor>
                    <Anchor href="/static/acceptable-use.html" target="_blank">
                        Acceptable Use Policy
                    </Anchor>
                </Stack>
            </Card>
        );
    }

    return (
        <>
            {modals}
            <Container size="xs" pl="sm" pr="sm" pb="sm">
                {cards}
            </Container>
        </>
    );
}
