import { Auth } from "aws-amplify";
import axios, { AxiosError } from "axios";
import { SetGlobalMessageCallbackFunc } from "../globalmessage";

const api = axios.create({
    baseURL: global.config.backendURI,
});

if (global.config.authType === "COGNITO") {
    api.interceptors.request.use(async (req) => {
        try {
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            req.headers.Authorization = jwtToken;
        } catch (error) {
            console.error("Failed to get the Cognito token:", error);
        }
        req.headers.Domain = window.location.hostname;

        return req;
    });
}

function isValidAccountPath(): boolean {
    const path = window.location.pathname;
    const regex = /^\/account(\/|$)/;
   return regex.test(path);
}

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error: AxiosError) {
        if (error.response?.status === 401) {                
            try {
                const data = error.response?.data;
                if (data && typeof data === "object") {
                    if ("message" in data &&
                        data["message"] === "Subscription has expired"
                    ) {
                        console.log("Subscription has expired");
                        if (!isValidAccountPath()) {
                            // show global message
                            if (global.setGlobalMessage) {
                                const callback : SetGlobalMessageCallbackFunc = (button, navigate) => {
                                    navigate("/account");
                                };
                                global.setGlobalMessage("", "Please visit your personal portal to continue your subscription", ["Go to portal"], callback);
                            }
                        }
                    }
                }
            }
            catch (e: unknown) {}
        }
        return Promise.reject(error);
    }
);

function updateChangedItems<T extends { id: number }>(prevItems: T[], newItems: T[]): T[] {
    const itemMap = new Map(prevItems.map(item => [item.id, item]));
    
    return newItems.map(newItem => {
        const oldItem = itemMap.get(newItem.id);
        return oldItem && JSON.stringify(oldItem) === JSON.stringify(newItem)
            ? oldItem
            : newItem;
    });
}


export { api, updateChangedItems };
