// Clouds

export enum SyncState {
    Synced = 0,
    InSync = 1,
}

export enum CloudType {
    AWS = "AWS",
    AZURE = "AZURE",
    K8S = "K8S",
    HUAWEI = "HUAWEI",
    GOOGLE = "GOOGLE",
    DIGITALOCEAN = "DIGITAL-OCEAN"
}


export interface ICloud {
    id: number;
    name: string;
    cloud_type: CloudType;
    
    aws_region: string;
    aws_key: string;
    aws_secret_key: string;
    
    azure_subscription_id: string;
    azure_tenant_id: string;
    azure_client_id: string;
    azure_client_secret: string;
    
    k8s_config: string;
    
    huawei_region: string;
    huawei_access_key: string;
    huawei_secret_key: string; 
    huawei_project_id?: string;    

    google_config: string;

    digitalocean_token: string;
    digitalocean_access_key: string;
    digitalocean_secret_key: string;

    last_successful_sync?: string;
    sync_state: SyncState;
    sync_start?: string;
    sync_stop?: string;
    sync_msg?: string;

    enabled?: boolean;
}

export interface ICloudAdd
    extends Omit<
        ICloud,
        | "id"
        | "sync_state"
        | "sync_start"
        | "sync_stop"
        | "sync_msg"
        | "last_successful_sync"
        | "enabled"
    > {}

export interface ICloudView
    extends Omit<
        ICloud,
        "aws_secret_key" | "azure_client_secret" | "k8s_config" | "huawei_secret_key" |  "google_config" | "digitalocean_token" | "digitalocean_secret_key"
    > {}

export interface ICloudTest {
    id?: number;
    cloud_type?: CloudType;

    aws_region?: string;
    aws_key?: string;
    aws_secret_key?: string;

    azure_subscription_id?: string;
    azure_tenant_id?: string;
    azure_client_id?: string;
    azure_client_secret?: string;

    k8s_config?: string;

    huawei_region?: string;
    huawei_access_key?: string;
    huawei_secret_key?: string;
    huawei_project_id?: string;

    google_config?: string;

    digitalocean_token?: string;
    digitalocean_access_key?: string;
    digitalocean_secret_key?: string;
}

export interface ICloudTestResultParameter {
    title: string;
    isGood: boolean;
    msg?: string;
}

export interface ICloudTestResult {
    parameters: ICloudTestResultParameter[];
}
