import React, {
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    PressEventCoordinates,
    PressHandlingOptions,
    Space as ZoomSpace,
} from "react-zoomable-ui";

import { HEADER_HEIGHT } from "../components/Header";
import { Alert, Box, Loader, LoadingOverlay, px } from "@mantine/core";
import { MapVPC } from "../components/MapVPC";
import { useMap } from "../core/hooks/map";
import { MapGroup } from "../components/MapGroup";
import { IconAlertTriangle } from "@tabler/icons-react";
import { MapSelection } from "../components/MapCommon";
import { MapNetwork } from "../components/MapNetwork";
import { MapLines } from "../components/MapLines";
import { ILink } from "../core/models/ILinks";
import { ShowModalError } from "../core/oasiserror";
import { AxiosError } from "axios";
import { useXarrow } from "react-xarrows";
import InitSettingsContext, { CloudMapSettings } from "../core/initsettings/InitSettingsContext";
import { useEffectAfterMount } from "../core/hooks/useEffectAfterMount";
import MapMenu, { MapMenuItem } from "../components/MapMenu";

export function CloudMap() {
    const spaceRef = React.useRef<ZoomSpace | null>(null);
    const innerDivRef = React.useRef<HTMLDivElement | null>(null);
    const {
        loading: mapLoading,
        error,
        fetchMap,
        themap,
        getLinksVM,
    } = useMap();
    const [linesLoading, setLinesLoading] = useState(false);
    const [linesLoadingPosition, setLinesLoadingPosition] = useState<{
        x: number;
        y: number;
    }>({ x: 0, y: 0 });
    const [lines, setLines] = useState<ILink[]>([]);
    const latestRequestSelectedLineRef = useRef(0);
    const [selectedLineIndex, setSelectedLineIndex] = useState<number>(-1);
    const [zoomFactor, setZoomFactor] = useState(1);
    const refreshLines = useXarrow();

    // Selection
    const [selection, setSelection] = useState<MapSelection | undefined>(
        undefined
    );

    useEffect(() => {
        const fetchData = async () => {
            if (selection && selection.type === "vm") {
                const currentRequestId = ++latestRequestSelectedLineRef.current;
                setLinesLoading(true);
                try {
                    const res = await getLinksVM(Number(selection.key));
                    if (
                        currentRequestId ===
                        latestRequestSelectedLineRef.current
                    ) {
                        setLines(res.links);
                    }
                } catch (e: unknown) {
                    if (
                        currentRequestId ===
                        latestRequestSelectedLineRef.current
                    ) {
                        ShowModalError(
                            `Error while getting links for VM`,
                            e as AxiosError
                        );
                    }
                }
                if (currentRequestId === latestRequestSelectedLineRef.current) {
                    setLinesLoading(false);
                }
            }
        };
        fetchData();
    }, [selection]);

    const { demoStateCurrent, cloudMapSettings, setCloudMapSettings } = useContext(InitSettingsContext);
    useEffectAfterMount(() => {
        setSelection(undefined);
        fetchMap();
    }, [demoStateCurrent]);

    useEffect(() => {
        const moveToCenter = () => {
            if (themap && spaceRef.current && innerDivRef.current) {
                spaceRef.current.viewPort?.camera.centerFitElementIntoView(
                    innerDivRef.current,
                    {
                        elementExtraMarginForZoomInClientSpace: px("2rem"),
                    },
                    {
                        durationMilliseconds: 1000,
                    }
                );
            } else if (themap) {
                // wait spaceRef and innerDivRef will render
                setTimeout(moveToCenter, 25);
            }
        };

        moveToCenter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themap?.vpcs]);

    function onDecideHowToHandlePress(
        e: MouseEvent | TouchEvent,
        coordinates: PressEventCoordinates
    ): PressHandlingOptions | undefined {
        if (e.target && e.target instanceof Element) {
            if (e.target.closest("button")) {
                return { ignorePressEntirely: true };
            }

            const arrow = e.target.closest(".map-arrow");
            if (arrow) {
                return {
                    onTap() {
                        setSelectedLineIndex(
                            Number(arrow.getAttribute("data"))
                        );
                    },
                };
            }

            const item = e.target.closest(
                ".map-vpc, .map-subnet, .map-vm, .map-network"
            );
            if (item) {
                let type: "vpc" | "subnet" | "vm" | "network" | null = null;
                const id = item.getAttribute("data-id");

                if (item.classList.contains("map-vpc")) {
                    type = "vpc";
                } else if (item.classList.contains("map-subnet")) {
                    type = "subnet";
                } else if (item.classList.contains("map-vm")) {
                    type = "vm";
                } else if (item.classList.contains("map-network")) {
                    type = "network";
                }

                if (type && id) {
                    const selection: MapSelection = {
                        type: type,
                        key: id,
                    };
                    return {
                        onTap(coordinates) {
                            setSelectedLineIndex(-1);
                            setLinesLoadingPosition({
                                x: coordinates.clientX,
                                y: coordinates.clientY,
                            });
                            setLines([]);
                            setSelection(selection);
                        },
                    };
                }
            }
        }
        return {
            onTap() {
                if (selectedLineIndex >= 0) {
                    setSelectedLineIndex(-1);
                } else {
                    setLines([]);
                    setSelection(undefined);
                }
            },
        };
    }

    const menuItems: MapMenuItem[] = [
        { 
            id: "compact", 
            label: "Compact view",
            description: "Compact view of entities",
            isActive: cloudMapSettings?.showDetails == false,
            onClick: () => {
                if (setCloudMapSettings && cloudMapSettings) {
                    const newSettings : CloudMapSettings = { ...cloudMapSettings, showDetails: false };
                    setCloudMapSettings(newSettings);
                    setTimeout(refreshLines, 0);
                }
            }
        },
        { 
            id: "detail", 
            label: "Detail view",
            description: "Detailed view of entities",
            isActive: cloudMapSettings?.showDetails == true,
            onClick: () => {
                if (setCloudMapSettings && cloudMapSettings) {
                    const newSettings : CloudMapSettings = { ...cloudMapSettings, showDetails: true };
                    setCloudMapSettings(newSettings);
                    setTimeout(refreshLines, 0);
                }
            }
        },
    ];

    let content: ReactNode | null = null;
    if (mapLoading) {
        content = (
            <Loader
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
        );
    } else if (error || !themap) {
        content = (
            <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Cannot get the map"
                color="red"
                mt={"xs"}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                {error}
            </Alert>
        );
    } else {
        content = (
            <ZoomSpace
                ref={spaceRef}
                onCreate={(viewPort) => {
                    viewPort.camera.moveBy(
                        -document.documentElement.clientWidth,
                        -document.documentElement.clientHeight
                    );
                }}
                onDecideHowToHandlePress={onDecideHowToHandlePress}
                onUpdated={(ViewPort) => {
                    setZoomFactor(ViewPort.zoomFactor);
                    setTimeout(refreshLines, 0);
                }}
            >
                <div style={{ position: "absolute" }} ref={innerDivRef}>
                    <MapGroup
                        borderLineStyle="none"
                        columnPolicy="squareRow"
                        spacing="5rem"
                        verticalSpacing="5rem"
                    >
                        {themap.vpcs.map((vpc) => (
                            <MapVPC
                                key={vpc.id}
                                vpc={vpc}
                                selection={selection}
                            />
                        ))}
                    </MapGroup>
                    <MapGroup borderLineStyle="none" columnPolicy="row">
                        {themap.inodes.map((node) => (
                            <MapNetwork
                                key={node}
                                node={node}
                                selection={selection}
                            />
                        ))}
                    </MapGroup>
                    <MapLines
                        from={selection}
                        lines={lines}
                        zoomFactor={zoomFactor}
                        selectedIndex={selectedLineIndex}
                    />
                </div>
            </ZoomSpace>
        );
    }
    return (
        <>            
            <div
                style={{
                    width: "100vw",
                    height: `calc(100vh - ${HEADER_HEIGHT})`,
                    position: "relative",
                }}
            >                
                {content}
                <MapMenu menuItems={menuItems} />
            </div>
            {linesLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1000,
                        pointerEvents: "none",
                    }}
                >
                    <Loader
                        sx={{
                            position: "absolute",
                            left: linesLoadingPosition.x,
                            top: linesLoadingPosition.y,
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                </Box>
            )}
        </>
    );
}
