import {} from "./config";

import ReactDOM from "react-dom/client";
import CognitoApp from "./CognitoApp";
import App from "./App";
import InitSettingsProvider from "./core/initsettings/InitSettingsProvider";
import ReactGA from "react-ga4";
import { hotjar } from 'react-hotjar';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const app =
    global.config.authType === "COGNITO" ? (
        <CognitoApp />
    ) : (
        <InitSettingsProvider>
            <App />
        </InitSettingsProvider>
    );

root.render(app);

if (global.config.GMTId) {
    console.log("ReactGA.initialize ", global.config.GMTId);
    ReactGA.initialize(global.config.GMTId);
    ReactGA.send({ hitType: "pageview", page: "/" });
}

if (global.config.hotjarSettings) {
    hotjar.initialize(global.config.hotjarSettings);
}