import React, { ReactNode, useEffect, useState } from "react";
import { useInitSettings } from "../hooks/initsettings";
import { Button, Container, Loader, Text, Title } from "@mantine/core";
import InitSettingsContext, { CloudMapSettings } from "./InitSettingsContext";
import { Auth } from "aws-amplify";
import { useDemo } from "../hooks/demo";

interface InitSettingsProviderProps {
    children: ReactNode;
}

const InitSettingsProvider = ({ children }: InitSettingsProviderProps) => {
    const [user, setUser] = useState(null);
    const { loading, error, data } = useInitSettings();
    const {
        demoStateCurrent,
        setDemoStateCurrentDirect,
        demoStateLoading,
        setDemo,
    } = useDemo(data?.demoState);
    const [cloudMapSettings, setCloudMapSettings] = useState<
        CloudMapSettings | undefined
    >({ showDetails: false });

    useEffect(() => {
        setDemoStateCurrentDirect(data?.demoState ?? false);
    }, [data?.demoState]);

    useEffect(() => {
        checkCurrentUser();
    }, []);

    const checkCurrentUser = async () => {
        if (global.config.authType === "COGNITO") {
            try {
                const userData = await Auth.currentAuthenticatedUser();
                setUser(userData);
            } catch (error) {
                console.log("Not authenticated in Cognito", error);
            }
        }
    };

    if (loading) {
        return (
            <Loader
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
        );
    }

    if (error || !data) {
        const refreshPage = () => {
            window.location.reload();
        };

        const signOut = () => {
            Auth.signOut();
        };

        return (
            <Container
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                }}
            >
                <Title color="red">Oasis Defender failed to initialize.</Title>
                <Text size="lg" style={{ margin: "20px 0" }}>
                    {error}
                </Text>
                <Button onClick={refreshPage}>Refresh</Button>
                {user && (
                    <Button
                        variant="filled"
                        color="red"
                        onClick={signOut}
                        mt="20px"
                    >
                        Sign out
                    </Button>
                )}
            </Container>
        );
    }

    return (
        <InitSettingsContext.Provider
            value={{
                ...data,
                demoStateCurrent,
                demoStateLoading,
                setDemo,
                cloudMapSettings,
                setCloudMapSettings,
            }}
        >
            {children}
        </InitSettingsContext.Provider>
    );
};

export default InitSettingsProvider;
