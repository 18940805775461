import {
    Text,
    Box,
    Flex,
    Tooltip,
    rem,
    useMantineTheme,
    Badge,
} from "@mantine/core";
import { IconLetterA, IconWorld } from "@tabler/icons-react";
import { IVM } from "../core/models/IMap";
import { MapSelection } from "./MapCommon";
import UniversalIcon from "./UniversalMap/UniversalIcon";
import { useContext, useMemo } from "react";
import InitSettingsContext from "../core/initsettings/InitSettingsContext";

interface MapVMProps {
    vm: IVM;
    selection?: MapSelection;
}

export function MapVM({ vm, selection }: MapVMProps) {
    const { cloudMapSettings } = useContext(InitSettingsContext);
    const isSelected =
        selection && selection.type === "vm" && Number(selection.key) === vm.id;

    const theme = useMantineTheme();
    const isDark = theme.colorScheme === "dark";

    const memoDeps = [vm, cloudMapSettings?.showDetails, isDark, isSelected];
    return useMemo(() => {
        console.log("VM rerender");
        let textColor: string | undefined = undefined;
        let bgColor: string | undefined = undefined;
        let borderColor: string | undefined = undefined;
        let hoverBgColor: string | undefined = undefined;

        if (isDark) {
            textColor = isSelected ? theme.colors.blue[6] : undefined;
            bgColor = isSelected ? theme.colors.gray[0] : theme.colors.dark[6];
            hoverBgColor = isSelected ? undefined : theme.colors.dark[4];
            borderColor = theme.colors.dark[0];
        } else {
            textColor = isSelected ? "blue" : undefined;
            bgColor = isSelected ? "white" : theme.colors.blue[3];
            hoverBgColor = isSelected ? undefined : theme.colors.blue[2];
            borderColor = "black";
        }

        let typeIcon: string | undefined = undefined;
        let typeTooltip: string | undefined = undefined;
        if (vm.type) {
            switch (vm.type) {
                case "VM":
                    typeIcon = "IconServer";
                    typeTooltip = "Virtual Machine";
                    break;
                case "NAT":
                    typeIcon = "IconRouteAltLeft";
                    typeTooltip = "NAT";
                    break;
                case "POD":
                    typeIcon = "IconCube";
                    typeTooltip = "Pod";
                    break;
                case "SVC":
                    typeIcon = "IconSitemap";
                    typeTooltip = "Service";
                    break;
                case "Ingress":
                    typeIcon = "IconArrowsShuffle";
                    typeTooltip = "Ingress";
                    break;
                case "LAMBDA":
                    typeIcon = "IconLambda";
                    typeTooltip = "Function";
                    break;
                case "A_Record":
                    typeIcon = "IconLetterA";
                    typeTooltip = "A record";
                    break;
                case "MAN_ENV":
                    typeIcon = "IconBrandCodesandbox";
                    typeTooltip = "Managed environment";
                    break;
                case "ECS":
                    typeIcon = "IconApps";
                    typeTooltip = "Container Apps";
                    break;
                case "RDS":
                    typeIcon = "IconDatabase";
                    typeTooltip = "Relational Database Service";
                    break;
                case "IGW":
                    typeIcon = "IconTorii";
                    typeTooltip = "Internet Gateway";
                    break;
                case "ELB":
                    typeIcon = "IconLoadBalancer";
                    typeTooltip = "Elastic Load Balance";
                    break;
                default:
                    typeIcon = "IconAsterisk";
                    typeTooltip = vm.type;
            }
        }

        return (
            <Flex
                id={`map_vm${vm.id}`}
                className="map-vm"
                data-id={vm.id}
                bg={bgColor}
                gap="xs"
                justify="center"
                align="center"
                direction="row"
                pr="0.5rem"
                pl="0.5rem"
                miw="5rem"
                sx={{
                    border: `${rem(1)} solid ${borderColor}`,
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: hoverBgColor,
                    },
                }}
            >
                {typeIcon && (
                    <UniversalIcon
                        name={typeIcon}
                        tooltip={typeTooltip}
                        color={
                            isSelected
                                ? isDark
                                    ? theme.colors.blue[6]
                                    : "blue"
                                : undefined
                        }
                    />
                )}
                <Box
                    maw={"15rem"}
                    sx={{
                        color: textColor,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    <Tooltip label={vm.name} withArrow withinPortal>
                        <span>{vm.name}</span>
                    </Tooltip>
                    {vm.label && cloudMapSettings?.showDetails == true && (
                        <Box sx={{ borderTop: "solid 1px #555555a0" }}>
                            {vm.label.split(",").map((l, i) => (
                                <Tooltip
                                    label={l}
                                    withArrow
                                    withinPortal
                                    key={i}
                                >
                                    <Text size="xs">{l}</Text>
                                </Tooltip>
                            ))}
                        </Box>
                    )}
                </Box>
                {vm.publicIP ? (
                    <Tooltip label={vm.publicIP} withArrow withinPortal>
                        <IconWorld color={textColor} />
                    </Tooltip>
                ) : (
                    false
                )}
            </Flex>
        );
    }, memoDeps);
}
