import { createContext } from "react";
import { IInitSettings } from "../models/IInitSettings";

export interface CloudMapSettings {
    showDetails: boolean;    
}

export interface InitSettingsContextData extends IInitSettings {
    demoStateCurrent: boolean;
    demoStateLoading: boolean;
    setDemo: (state: boolean) => Promise<void>;
    cloudMapSettings?: CloudMapSettings;
    setCloudMapSettings?: (state: CloudMapSettings) => void;
}

const InitSettingsContext = createContext<InitSettingsContextData>({
    demoStateCurrent: false,
    demoStateLoading: false,
    setDemo: async (s) => {}    
});

export default InitSettingsContext;
