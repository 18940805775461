import { useState } from "react";
import { api } from "../api";


export function useDemo(demoStateInitial?: boolean) {
    const [ demoStateLoading, setLoading ] = useState(false);
    const [ demoStateCurrent, setDemoStateCurrentDirect ] = useState(demoStateInitial ?? false);

    async function setDemo(state: boolean) {
        setLoading(true);
        try {
            await api.post('/api/account/demo', JSON.stringify({ "state": state}), {
                headers: {
                    'Content-Type': 'application/json'
                    }
            });
            setDemoStateCurrentDirect(state);
            setLoading(false);
        }
        catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    return { demoStateCurrent, setDemoStateCurrentDirect, demoStateLoading, setDemo };
}