import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Alert,
    Button,
    Code,
    CopyButton,
    Flex,
    Group,
    LoadingOverlay,
    Menu,
    Paper,
    SegmentedControl,
    Space,
    Tabs,
    Text,
    Tooltip,
    useMantineTheme,
} from "@mantine/core";
import {
    IconAlertTriangle,
    IconArrowBack,
    IconChevronDown,
    IconCircle,
    IconCopy,
    IconFileDownload,
} from "@tabler/icons-react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { useAIAnalysisCompare } from "../core/hooks/aianalysis";
import { useMediaQuery } from "@mantine/hooks";
import { StackAnalyzeState } from "../core/models/IAIAnalysis";
import { ShowModalError } from "../core/oasiserror";
import { modals } from "@mantine/modals";
import InitSettingsContext from "../core/initsettings/InitSettingsContext";
import { useEffectAfterMount } from "../core/hooks/useEffectAfterMount";

function determineTemplateFormat(data: string): string {
    try {
        JSON.parse(data);
        return "json";
    } catch (jsonError) {
        return "yaml";
    }
}

function saveTemplateToFile(data: string, filenameWithoutExt: string) {
    const extention = determineTemplateFormat(data);
    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filenameWithoutExt}.${extention}`;
    link.click();
}

function UploadTemplateButton({
    saveTemplate,
}: {
    saveTemplate: (newTemplate: string) => void;
}) {
    const [dragActive, setDragActive] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileRead = (file: File) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            const content = e.target!.result as string;
            setInputKey(Date.now());
            saveTemplate(content);
        };

        reader.readAsText(file);
    };

    const handleDrag = function (e: React.DragEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: React.DragEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileRead(e.dataTransfer.files[0]);
        }
    };

    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileRead(e.target.files[0]);
        }
    };

    const openFileDialog = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <form
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
            onSubmit={(e) => e.preventDefault()}
        >
            <input
                key={inputKey}
                ref={inputRef}
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleChange}
            />
            <label
                htmlFor="file-upload"
                className={dragActive ? "drag-active" : ""}
            >
                <Tooltip
                    multiline
                    width={220}
                    label={
                        <>
                            You can edit the AI-generated template in your
                            favourite text editor and replace it before applying
                            the changes.
                            <br />
                            You can simply drag file over this button to upload
                            new template in UTF-8 encoding.
                        </>
                    }
                >
                    <Button
                        onClick={openFileDialog}
                        color={dragActive ? "teal" : "blue"}
                    >
                        Upload template for apply
                    </Button>
                </Tooltip>
            </label>
        </form>
    );
}

function AICompareTemplates() {
    const theme = useMantineTheme();
    const isDark = theme.colorScheme === "dark";
    const matches = useMediaQuery("(min-width: 80em)");
    const navigate = useNavigate();

    let { id } = useParams();
    const { loading, error, data, createchangeset, edit } =
        useAIAnalysisCompare(Number(id));
    const [view, setView] = useState<string>("unified");

    useEffect(() => {
        setView(matches ? "split" : "unified");
    }, [matches]);

    const saveTemplate = useCallback(
        async (newTemplate: string) => {
            try {
                await edit(newTemplate);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot edit new template to deployment "${data?.name}"`,
                    e
                );
            }
        },
        [data, edit]
    );

    const revertHumanTemplate = async () => {
        try {
            if (data?.ai_template) {
                await edit(data?.ai_template);
            }
        } catch (e: unknown) {
            ShowModalError(`Cannot revert user template to AI template"`, e);
        }
    };

    const makeCreatechangeset = async () => {
        modals.openConfirmModal({
            title: "Create a deployment changeset",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to create a deployment changeset in
                    your cloud?
                </Text>
            ),
            labels: {
                confirm: "Create changeset",
                cancel: "No, don't do it",
            },
            confirmProps: { color: "green" },
            onConfirm: async () => {
                try {
                    await createchangeset();
                    navigate(`/ai/stack/${id}`);
                } catch (e: unknown) {
                    ShowModalError(`Cannot create a deployment changeset`, e);
                }
            },
        });
    };

    const { demoStateCurrent } = useContext(InitSettingsContext);
    useEffectAfterMount(() => {
        navigate(`/ai`);
    }, [demoStateCurrent]);

    if (!loading && error) {
        return (
            <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Unable to obtain information for deployment template comparison"
                color="red"
                mt={"xs"}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                {error}
            </Alert>
        );
    }
    const viewControl = (
        <SegmentedControl
            value={view}
            onChange={setView}
            size="sm"
            radius="xl"
            ml="auto"
            data={[
                {
                    label: "Split",
                    value: "split",
                },
                {
                    label: "Unified",
                    value: "unified",
                },
            ]}
        />
    );

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Flex
                m="xs"
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="nowrap"
            >
                <Link to={!isNaN(Number(id)) ? `/ai/stack/${id}` : "/ai"}>
                    <Button color="blue" leftIcon={<IconArrowBack />}>
                        Back
                    </Button>
                </Link>
                <div>
                    <Text span c="dimmed">
                        {data?.cloud_name}
                    </Text>
                    <Text span>
                        {" "}
                        —{" "}
                        {data?.state === StackAnalyzeState.FixReady && (
                            <Tooltip label="Fix ready">
                                <IconCircle
                                    color="orange"
                                    fill="orange"
                                    size="0.8rem"
                                />
                            </Tooltip>
                        )}{" "}
                        {data?.name}
                    </Text>
                </div>
            </Flex>
            {data?.state !== StackAnalyzeState.FixReady ? (
                <Text m="xs">
                    {/* if undefined/null do not show anything */}
                    {data?.state !== undefined
                        ? "The deployment is not in the FixReady analysis state."
                        : ""}
                </Text>
            ) : (
                <Tabs
                    color="blue"
                    variant="pills"
                    radius="xl"
                    defaultValue="changes"
                >
                    <Tabs.List position="center">
                        <Tabs.Tab value="changes" fw="bold" lts={1.5}>
                            Changes made by AI
                        </Tabs.Tab>
                        <Tabs.Tab value="compare" fw="bold" lts={1.5}>
                            Compare templates
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="changes" pt="xs">
                        <Group mr="md">{viewControl}</Group>
                        {data?.ai_fix_result.map((item, i) => (
                            <Paper
                                key={i}
                                ml="md"
                                mr="md"
                                mb="xl"
                                shadow="lg"
                                radius="lg"
                            >
                                <Flex>
                                    <Text size="2.5rem" ml="sm" color="gray">
                                        {i + 1}
                                    </Text>
                                    <div>
                                        <Text pt="sm" pl="sm" c="dimmed">
                                            {item.issue.LogicalName}
                                        </Text>
                                        <Text pl="sm" pr="sm">
                                            {item.issue.Issue}
                                        </Text>
                                    </div>
                                </Flex>
                                <Text pt="sm" pl="md" c="dimmed">
                                    What does AI fix?
                                </Text>
                                <Text pl="md" pr="sm">
                                    {item.what}
                                </Text>
                                <Text pt="md" pl="md" pr="sm" c="dimmed">
                                    How can AI fix it?
                                </Text>
                                <Text pl="md" pr="sm">
                                    {item.how}
                                </Text>
                                {item.template && (
                                    <>
                                        <Text
                                            c="dimmed"
                                            pl="md"
                                            pr="sm"
                                            pt="sm"
                                        >
                                            How does AI change templates?
                                        </Text>
                                        <ReactDiffViewer
                                            oldValue={item.template.orig}
                                            newValue={item.template.new}
                                            splitView={view === "split"}
                                            useDarkTheme={isDark}
                                            compareMethod={DiffMethod.WORDS}
                                            linesOffset={
                                                item.template.start_line
                                            }
                                        />
                                    </>
                                )}
                                {item.cli && item.cli.length > 0 && (
                                    <>
                                        <Text
                                            c="dimmed"
                                            pl="md"
                                            pr="sm"
                                            pt="sm"
                                        >
                                            How does AI suggest fixing using CLI
                                            commands?
                                        </Text>
                                        <Code block ml="xs" mr="xs">
                                            {item.cli.join("\n")}
                                        </Code>
                                    </>
                                )}
                                <Space h="md" />
                            </Paper>
                        ))}
                    </Tabs.Panel>

                    <Tabs.Panel value="compare" pt="xs">
                        <Group m="xs">
                            <Text>
                                Compare the changes made by AI to the deployment
                                template, then create a changeset for the
                                deployment in your cloud.
                                {data?.human_template !== data?.ai_template && (
                                    <>
                                        {
                                            " AI template was changed by user, to revert changes press button "
                                        }
                                        <Button
                                            size="compact-md"
                                            color="blue"
                                            onClick={revertHumanTemplate}
                                        >
                                            Revert
                                        </Button>
                                    </>
                                )}
                            </Text>
                        </Group>
                        <Group ml="xs" mr="md" mb="xs">
                            <Button color="green" onClick={makeCreatechangeset}>
                                Create changeset
                            </Button>
                            <Menu shadow="md" withArrow transitionProps={{ transition: 'pop', duration: 100, exitDuration: 1000 }} width={200}>
                                <Menu.Target>
                                    <Button>
                                        Export original template{" "}
                                        <IconChevronDown
                                            style={{ marginLeft: "0.5rem" }}
                                        />
                                    </Button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <CopyButton value={data.current_template}>
                                        {({ copied, copy }) => (
                                            <Menu.Item
                                                onClick={copy}
                                                icon={
                                                    <IconCopy
                                                        style={{
                                                            width: "14px",
                                                            height: "14px",
                                                        }}
                                                    />
                                                }
                                            >
                                                {copied
                                                    ? "Copied"
                                                    : "Copy to clipboard"}
                                            </Menu.Item>
                                        )}
                                    </CopyButton>
                                    <Menu.Item
                                        icon={
                                            <IconFileDownload
                                                style={{
                                                    width: "14px",
                                                    height: "14px",
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            saveTemplateToFile(
                                                data.current_template,
                                                data.name + ".original"
                                            );
                                        }}
                                    >
                                        Save to file
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                            <Menu shadow="md" withArrow transitionProps={{ transition: 'pop', duration: 100, exitDuration: 1000 }} width={200}>
                                <Menu.Target>
                                    <Button>
                                        Export fixed template{" "}
                                        <IconChevronDown
                                            style={{ marginLeft: "0.5rem" }}
                                        />
                                    </Button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <CopyButton value={data.human_template}>
                                        {({ copied, copy }) => (
                                            <Menu.Item
                                                onClick={copy}
                                                icon={
                                                    <IconCopy
                                                        style={{
                                                            width: "14px",
                                                            height: "14px",
                                                        }}
                                                    />
                                                }
                                            >
                                                {copied
                                                    ? "Copied"
                                                    : "Copy to clipboard"}
                                            </Menu.Item>
                                        )}
                                    </CopyButton>
                                    <Menu.Item
                                        icon={
                                            <IconFileDownload
                                                style={{
                                                    width: "14px",
                                                    height: "14px",
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            saveTemplateToFile(
                                                data.human_template,
                                                data.name + ".fixed"
                                            );
                                        }}
                                    >
                                        Save to file
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                            <UploadTemplateButton saveTemplate={saveTemplate} />
                            {viewControl}
                        </Group>
                        <ReactDiffViewer
                            oldValue={data?.current_template ?? undefined}
                            newValue={data?.human_template ?? undefined}
                            splitView={view === "split"}
                            useDarkTheme={isDark}
                            compareMethod={DiffMethod.WORDS}
                        />
                    </Tabs.Panel>
                </Tabs>
            )}
        </>
    );
}

export default AICompareTemplates;
