import React, { useEffect, useState } from "react";
import { UseFormReturnType, useForm } from "@mantine/form";
import {
    CloudType,
    ICloudAdd,
    ICloudTest,
    ICloudView,
} from "../core/models/ICloud";
import {
    Anchor,
    Button,
    Code,
    CopyButton,
    Group,
    List,
    Modal,
    NativeSelect,
    PasswordInput,
    SegmentedControl,
    Select,
    Text,
    TextInput,
    Textarea,
    Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

interface AddCloudFormProps {
    onCancel: () => void;
    currentCloudRef: React.MutableRefObject<ICloudView | undefined>; // filled if EDIT
    makeTest: (name: string, cloud: ICloudTest) => void;
    makeAddCloud: (cloud: ICloudAdd) => void;
    makeEditCloud: (id: number, cloud: ICloudAdd) => void;
}

export const AddEditCloudForm = ({
    onCancel,
    currentCloudRef,
    makeTest,
    makeAddCloud,
    makeEditCloud,
}: AddCloudFormProps) => {
    const [cloudHelpOpened, { open: openCloudHelp, close: closeCloudHelp }] =
        useDisclosure(false);
    const cloudTypes = [
        CloudType.K8S,
        CloudType.AWS,
        CloudType.AZURE,
        CloudType.HUAWEI,
        CloudType.GOOGLE,
        CloudType.DIGITALOCEAN,
    ];
    const awsRegions = [
        "ap-east-1",
        "ap-northeast-1",
        "ap-northeast-2",
        "ap-northeast-3",
        "ap-south-1",
        "ap-south-2",
        "ap-southeast-1",
        "ap-southeast-2",
        "ap-southeast-3",
        "ap-southeast-4",
        "ca-central-1",
        "eu-central-1",
        "eu-central-2",
        "eu-north-1",
        "eu-south-1",
        "eu-south-2",
        "eu-west-1",
        "eu-west-2",
        "eu-west-3",
        "me-central-1",
        "me-south-1",
        "sa-east-1",
        "us-east-1",
        "us-east-2",
        "us-west-1",
        "us-west-2",
    ];
    const huaweiRegions = [
        "af-south-1",
        "cn-north-4",
        "cn-north-1",
        "cn-east-2",
        "cn-east-3",
        "cn-east-5",
        "cn-south-1",
        "cn-south-4",
        "cn-southwest-2",
        "ap-southeast-2",
        "cn-north-9",
        "cn-north-11",
        "ap-southeast-1",
        "ap-southeast-3",
        "sa-brazil-1",
        "la-north-2",
        "cn-south-2",
        "cn-north-2",
        "la-south-2",
        "na-mexico-1",
        "ap-southeast-4",
        "tr-west-1",
        "eu-west-101",
        "me-east-1",
        "ae-ad-1",
        "eu-west-0",
        "ru-northwest-2",
        "my-kualalumpur-1",
        "ru-moscow-1",
    ];
    const [addCloudManualType, setAddCloudManualType] = useState("script"); // "script" | "manual"
    const [testLoading, setTestLoading] = useState(false);

    const form = useForm<ICloudAdd>({
        initialValues: {
            name: currentCloudRef.current?.name ?? "",
            cloud_type: currentCloudRef.current?.cloud_type ?? CloudType.K8S,

            aws_region: currentCloudRef.current?.aws_region ?? "",
            aws_key: currentCloudRef.current?.aws_key ?? "",
            aws_secret_key: "",

            azure_subscription_id:
                currentCloudRef.current?.azure_subscription_id ?? "",
            azure_tenant_id: currentCloudRef.current?.azure_tenant_id ?? "",
            azure_client_id: currentCloudRef.current?.azure_client_id ?? "",
            azure_client_secret: "",

            k8s_config: "",

            huawei_region: currentCloudRef.current?.huawei_region ?? "",
            huawei_access_key: currentCloudRef.current?.huawei_access_key ?? "",
            huawei_secret_key: "",
            huawei_project_id: undefined,

            google_config: "",

            digitalocean_token: "",
            digitalocean_access_key: currentCloudRef.current?.digitalocean_access_key ?? "",
            digitalocean_secret_key: "",
        },

        validate: {
            name: (value) =>
                value.length < 2 ? "Name must have at least 2 letters" : null,
            cloud_type: (value) =>
                cloudTypes.indexOf(value) >= 0 ? null : "Invalid value",

            aws_region: (value, values) =>
                values.cloud_type !== CloudType.AWS ||
                awsRegions.indexOf(value) >= 0
                    ? null
                    : "Invalid value",
            aws_key: (value, values) =>
                values.cloud_type !== CloudType.AWS || value.length >= 2
                    ? null
                    : "Invalid value",
            aws_secret_key: (value, values) =>
                values.cloud_type !== CloudType.AWS ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",

            azure_subscription_id: (value, values) =>
                values.cloud_type !== CloudType.AZURE || value.length >= 2
                    ? null
                    : "Invalid value",
            azure_tenant_id: (value, values) =>
                values.cloud_type !== CloudType.AZURE || value.length >= 2
                    ? null
                    : "Invalid value",
            azure_client_id: (value, values) =>
                values.cloud_type !== CloudType.AZURE || value.length >= 2
                    ? null
                    : "Invalid value",
            azure_client_secret: (value, values) =>
                values.cloud_type !== CloudType.AZURE ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",

            k8s_config: (value, values) =>
                values.cloud_type !== CloudType.K8S ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",

            huawei_access_key: (value, values) =>
                values.cloud_type !== CloudType.HUAWEI || value.length >= 2
                    ? null
                    : "Invalid value",

            huawei_secret_key: (value, values) =>
                values.cloud_type !== CloudType.HUAWEI ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",

            huawei_region: (value, values) =>
                values.cloud_type !== CloudType.HUAWEI ||
                huaweiRegions.indexOf(value) >= 0 ||
                value.length >= 2
                    ? null
                    : "Invalid value",

            google_config: (value, values) =>
                values.cloud_type !== CloudType.GOOGLE ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",

            digitalocean_token: (value, values) =>
                values.cloud_type !== CloudType.DIGITALOCEAN ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",

            digitalocean_access_key: (value, values) =>
                values.cloud_type !== CloudType.DIGITALOCEAN ||
                value.length >= 2
                    ? null
                    : "Invalid value",

            digitalocean_secret_key: (value, values) =>
                values.cloud_type !== CloudType.DIGITALOCEAN ||
                value.length >= 2 ||
                currentCloudRef.current
                    ? null
                    : "Invalid value",
        },
    });

    const handleSubmit = (values: any, event: any) => {
        if (currentCloudRef.current) {
            makeEditCloud(currentCloudRef.current.id, values);
        } else {
            makeAddCloud(values);
        }
    };

    const onTestConnection = async () => {
        if (makeTest) {
            const cloudAdd: ICloudTest = {
                id: currentCloudRef.current?.id,
                cloud_type: form.values.cloud_type,
                aws_region: form.values.aws_region,
                aws_key: form.values.aws_key,
                aws_secret_key: form.values.aws_secret_key
                    ? form.values.aws_secret_key
                    : undefined,

                azure_subscription_id: form.values.azure_subscription_id,
                azure_tenant_id: form.values.azure_tenant_id,
                azure_client_id: form.values.azure_client_id,
                azure_client_secret: form.values.azure_client_secret
                    ? form.values.azure_client_secret
                    : undefined,

                k8s_config: form.values.k8s_config
                    ? form.values.k8s_config
                    : undefined,

                huawei_region: form.values.huawei_region,
                huawei_access_key: form.values.huawei_access_key,
                huawei_secret_key: form.values.huawei_secret_key
                    ? form.values.huawei_secret_key
                    : undefined,
                huawei_project_id: form.values.huawei_project_id
                    ? form.values.huawei_project_id
                    : undefined,

                google_config: form.values.google_config
                    ? form.values.google_config
                    : undefined,

                digitalocean_token: form.values.digitalocean_token
                    ? form.values.digitalocean_token
                    : undefined,
                digitalocean_access_key: form.values.digitalocean_access_key
                    ? form.values.digitalocean_access_key
                    : undefined,
                digitalocean_secret_key: form.values.digitalocean_secret_key
                    ? form.values.digitalocean_secret_key
                    : undefined,
            };
            setTestLoading(true);
            await makeTest(form.values.name, cloudAdd);
            setTestLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput
                    withAsterisk
                    label="Name"
                    placeholder="Cloud name"
                    data-autofocus
                    {...form.getInputProps("name")}
                />

                <NativeSelect
                    data={cloudTypes}
                    label="Select cloud type"
                    withAsterisk
                    disabled={currentCloudRef.current !== undefined}
                    {...form.getInputProps("cloud_type")}
                />

                {form.values.cloud_type === CloudType.AWS && (
                    <>
                        <Anchor
                            component="button"
                            type="button"
                            w="100%"
                            pt="xs"
                            onClick={openCloudHelp}
                        >
                            How can I set up an access for Oasis Defender?
                        </Anchor>
                        <Select
                            label="Region"
                            placeholder="Pick one"
                            searchable
                            nothingFound="No regions"
                            data={awsRegions}
                            withinPortal={true}
                            withAsterisk
                            {...form.getInputProps("aws_region")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Key"
                            placeholder=""
                            {...form.getInputProps("aws_key")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Secret Key"
                            placeholder={
                                currentCloudRef.current ? "Use saved value" : ""
                            }
                            {...form.getInputProps("aws_secret_key")}
                        />
                    </>
                )}

                {form.values.cloud_type === CloudType.AZURE && (
                    <>
                        <Anchor
                            component="button"
                            type="button"
                            w="100%"
                            pt="xs"
                            onClick={openCloudHelp}
                        >
                            How can I set up an access for Oasis Defender?
                        </Anchor>
                        <PasswordInput
                            withAsterisk
                            label="Subscription ID"
                            placeholder=""
                            {...form.getInputProps("azure_subscription_id")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Tenant ID"
                            placeholder=""
                            {...form.getInputProps("azure_tenant_id")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Client ID"
                            placeholder=""
                            {...form.getInputProps("azure_client_id")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Client Secret"
                            placeholder={
                                currentCloudRef.current ? "Use saved value" : ""
                            }
                            {...form.getInputProps("azure_client_secret")}
                        />
                    </>
                )}
                {form.values.cloud_type === CloudType.K8S && (
                    <>
                        <Textarea
                            withAsterisk
                            label="Kubernetes config"
                            minRows={10}
                            placeholder={
                                currentCloudRef.current
                                    ? "Use saved value"
                                    : `clusters:
    - cluster:
        certificate-authority-data: <...>
        server: https://192.168.1.201:6443
        name: kubernetes
<...>`
                            }
                            {...form.getInputProps("k8s_config")}
                        />
                    </>
                )}
                {form.values.cloud_type === CloudType.HUAWEI && (
                    <>
                        <Anchor
                            component="button"
                            type="button"
                            w="100%"
                            pt="xs"
                            onClick={openCloudHelp}
                        >
                            How can I set up an access for Oasis Defender?
                        </Anchor>
                        <Select
                            label="Region"
                            placeholder="Pick one"
                            searchable
                            nothingFound="No regions"
                            data={huaweiRegions}
                            withinPortal={true}
                            withAsterisk
                            {...form.getInputProps("huawei_region")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Access Key"
                            placeholder=""
                            {...form.getInputProps("huawei_access_key")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Secret Key"
                            placeholder={
                                currentCloudRef.current ? "Use saved value" : ""
                            }
                            {...form.getInputProps("huawei_secret_key")}
                        />
                        <TextInput
                            label="Project ID"
                            placeholder="optional"
                            {...form.getInputProps("huawei_project_id")}
                        />
                    </>
                )}
                {form.values.cloud_type === CloudType.GOOGLE && (
                    <>
                        <Anchor
                            component="button"
                            type="button"
                            w="100%"
                            pt="xs"
                            onClick={openCloudHelp}
                        >
                            How can I set up an access for Oasis Defender?
                        </Anchor>
                        <Textarea
                            withAsterisk
                            label="Google config"
                            minRows={10}
                            placeholder={
                                currentCloudRef.current
                                    ? "Use saved value"
                                    : `{
  "type": "service_account",
  "project_id": <...>,
  "private_key_id": <...>,
  "private_key": 
  <...>
}`
                            }
                            {...form.getInputProps("google_config")}
                        />
                    </>
                )}

                {form.values.cloud_type === CloudType.DIGITALOCEAN && (
                    <>
                        <Anchor
                            component="button"
                            type="button"
                            w="100%"
                            pt="xs"
                            onClick={openCloudHelp}
                        >
                            How can I set up an access for Oasis Defender?
                        </Anchor>
                        <PasswordInput
                            withAsterisk
                            label="Personal Access Token"
                            placeholder={
                                currentCloudRef.current ? "Use saved value" : ""
                            }
                            {...form.getInputProps("digitalocean_token")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Spaces Access Key"
                            placeholder=""
                            {...form.getInputProps("digitalocean_access_key")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Spaces Secret Key"
                            placeholder={
                                currentCloudRef.current ? "Use saved value" : ""
                            }
                            {...form.getInputProps("digitalocean_secret_key")}
                        />
                    </>
                )}

                <Button
                    mt="md"
                    loading={testLoading}
                    onClick={onTestConnection}
                    disabled={!form.isValid()}
                >
                    Test connection
                </Button>
                <Group position="right" mt="md">
                    <Button onClick={onCancel} variant="default">
                        Cancel
                    </Button>
                    <Button color="blue" type="submit">
                        {currentCloudRef.current ? "Edit cloud" : "Add cloud"}
                    </Button>
                </Group>
            </form>

            <Modal
                title=<Title order={4}>
                    {" "}
                    Connect Your Cloud with Oasis Defender
                </Title>
                opened={cloudHelpOpened}
                onClose={closeCloudHelp}
                zIndex={300}
                size="auto"
                centered
            >
                {(form.values.cloud_type === CloudType.AWS ||
                    form.values.cloud_type === CloudType.AZURE) && (
                    <SegmentedControl
                        value={addCloudManualType}
                        onChange={setAddCloudManualType}
                        fullWidth
                        data={[
                            { label: "Script", value: "script" },
                            { label: "Manual", value: "manual" },
                        ]}
                        mb="sm"
                    />
                )}

                {form.values.cloud_type === CloudType.AWS &&
                    addCloudManualType === "script" &&
                    cloudConnectAWSScript()}

                {form.values.cloud_type === CloudType.AWS &&
                    addCloudManualType === "manual" &&
                    cloudConnectAWSManual()}

                {form.values.cloud_type === CloudType.AZURE &&
                    addCloudManualType === "script" &&
                    cloudConnectAzureScript()}

                {form.values.cloud_type === CloudType.AZURE &&
                    addCloudManualType === "manual" &&
                    cloudConnectAzureManual()}

                {form.values.cloud_type === CloudType.HUAWEI &&
                    cloudConnectHuaweiManual()}

                {form.values.cloud_type === CloudType.GOOGLE && (
                    <CloudConnectGoogleManual />
                )}

                {form.values.cloud_type === CloudType.DIGITALOCEAN && (
                    <CloudConnectDigitalOceanManual />
                )}

                <Group position="right" mt="md">
                    <Button color="blue" onClick={closeCloudHelp}>
                        Continue
                    </Button>
                </Group>
            </Modal>
        </>
    );

    function cloudConnectAWSScript(): React.ReactNode {
        return (
            <List type="ordered" w="90%">
                <List.Item>
                    Download{" "}
                    <Anchor
                        onClick={(e) => {
                            fetch(
                                "https://raw.githubusercontent.com/OasisDefender/OasisUsersCreation/main/aws/CloudFormation/OasisUserCloudFormationTemplate.json"
                            )
                                .then((response) => response.blob())
                                .then((blob) => {
                                    const link = document.createElement("a");
                                    link.href = URL.createObjectURL(blob);
                                    link.download =
                                        "OasisUserCloudFormationTemplate.json";
                                    link.click();
                                })
                                .catch(console.error);
                        }}
                    >
                        CloudFormation template
                    </Anchor>{" "}
                    from OasisDefender GitHub repository
                </List.Item>
                <List.Item>
                    Apply the downloaded CloudFormation template on AWS
                </List.Item>
                <List.Item>
                    From the created CloudFormation Stack Outputs, insert both{" "}
                    <b>OasisAccessKey</b> and <b>OasisSecretKey</b> into
                    OasisDefender:
                </List.Item>
                <PasswordInput
                    withAsterisk
                    label="Key"
                    placeholder=""
                    {...form.getInputProps("aws_key")}
                />
                <PasswordInput
                    withAsterisk
                    label="Secret Key"
                    placeholder=""
                    {...form.getInputProps("aws_secret_key")}
                />
            </List>
        );
    }

    function cloudConnectAWSManual(): React.ReactNode {
        return (
            <List type="ordered" w="90%">
                <List.Item>
                    Creating a New IAM User
                    <List listStyleType="disc">
                        <List.Item>
                            Sign in to your <b>AWS Management Console</b>
                        </List.Item>
                        <List.Item>
                            and go to the{" "}
                            <b>IAM (Identity and Access Management)</b> service
                        </List.Item>
                        <List.Item>
                            Select <b>Users</b> in the left navigation pane
                        </List.Item>
                        <List.Item>
                            Click the <b>Create User</b> button to create a new
                            user
                        </List.Item>
                        <List.Item>
                            Specify a username for the new user
                        </List.Item>
                    </List>
                </List.Item>
                <List.Item>
                    Set User Permissions
                    <List listStyleType="disc">
                        <List.Item>
                            Choose <b>Attach policies directly</b> from the{" "}
                            <b>Permissions options</b>
                        </List.Item>
                        <List.Item>
                            To create a <b>read-only</b> account:
                            <List listStyleType="circle">
                                <List.Item>
                                    Ensure the <b>SecurityAudit</b> policy is
                                    selected
                                </List.Item>
                            </List>
                        </List.Item>
                        <List.Item>
                            Review the user's settings and permissions, then
                            confirm
                        </List.Item>
                    </List>
                </List.Item>
                <List.Item>
                    Create the access key
                    <List listStyleType="disc">
                        <List.Item>
                            Click on the name of the user you created in the
                            list of users
                        </List.Item>
                        <List.Item>
                            Then click on the <b>Create access key</b> button to
                            generate an access key
                        </List.Item>
                        <List.Item>
                            Select the <b>Application running outside AWS</b>{" "}
                            use case
                        </List.Item>
                        <List.Item>Create the Access Key</List.Item>
                        <List.Item>
                            <i>Optional:</i> After creating the access key,
                            obtain a CSV file with the Access Key ID and Secret
                            Access Key
                        </List.Item>
                        <List.Item>
                            Next, insert both the <b>Access key</b> and{" "}
                            <b>Secret access key</b> into Oasis Defender:
                        </List.Item>
                        <PasswordInput
                            withAsterisk
                            label="Key"
                            placeholder=""
                            {...form.getInputProps("aws_key")}
                        />
                        <PasswordInput
                            withAsterisk
                            label="Secret Key"
                            placeholder=""
                            {...form.getInputProps("aws_secret_key")}
                        />
                    </List>
                </List.Item>
            </List>
        );
    }

    function cloudConnectAzureScript(): React.ReactNode {
        const azureScript =
            "curl -s https://raw.githubusercontent.com/OasisDefender/OasisUsersCreation/main/azure/azure_user.sh | bash";

        return (
            <List type="ordered" w="90%">
                <List.Item>
                    Open Azure CloudShell console in Bash mode
                </List.Item>
                <List.Item>
                    Execute script from OasisDefender GitHub repository:
                </List.Item>
                <CopyButton value={azureScript}>
                    {({ copied, copy }) => (
                        <Button color={copied ? "teal" : "blue"} onClick={copy}>
                            {copied ? "Code copied" : "Copy code"}
                        </Button>
                    )}
                </CopyButton>
                <Code block mt="sm" mb="sm">
                    {azureScript}
                </Code>
                <List.Item>
                    From the script outputs, insert <b>Tenant</b>,{" "}
                    <b>Subscription</b>, <b>App</b> and <b>Secret</b> into
                    OasisDefender:
                </List.Item>
                <PasswordInput
                    withAsterisk
                    label="Tenant ID"
                    placeholder=""
                    {...form.getInputProps("azure_tenant_id")}
                />
                <PasswordInput
                    withAsterisk
                    label="Subscription ID"
                    placeholder=""
                    {...form.getInputProps("azure_subscription_id")}
                />
                <PasswordInput
                    withAsterisk
                    label="Client ID"
                    placeholder=""
                    {...form.getInputProps("azure_client_id")}
                />
                <PasswordInput
                    withAsterisk
                    label="Client Secret"
                    placeholder=""
                    {...form.getInputProps("azure_client_secret")}
                />
            </List>
        );
    }

    function cloudConnectAzureManual(): React.ReactNode {
        return (
            <List type="ordered" w="90%">
                <List.Item w="100%">
                    Obtain a Subscription ID:
                    <List listStyleType="disc" w="100%">
                        <List.Item> Sign in to the Azure portal </List.Item>
                        <List.Item>
                            {" "}
                            Navigate to <b>Subscriptions</b>{" "}
                        </List.Item>
                        <List.Item>
                            {" "}
                            Insert the <b>Subscription ID</b> into Oazis
                            Defender:
                        </List.Item>
                    </List>
                </List.Item>
                <PasswordInput
                    withAsterisk
                    label="Subscription ID"
                    placeholder=""
                    {...form.getInputProps("azure_subscription_id")}
                />

                <br />
                <List.Item>
                    Register the application in Azure:
                    <List listStyleType="disc">
                        <List.Item>
                            Navigate to <b>App registrations</b>
                        </List.Item>
                        <List.Item>
                            On the <b>App registrations</b> page, select{" "}
                            <b>+ New registration</b>
                        </List.Item>
                        <List.Item>
                            On the <b>Register an application</b> page, fill out
                            the form as follows:
                            <br />
                            for <b>Name</b> enter <i>Oasis Defender</i>,
                            <br />
                            for <b>Supported account types</b> select{" "}
                            <i>
                                Accounts in this organizational directory only
                            </i>
                            .
                        </List.Item>
                        <List.Item>
                            Select Register to register your application and
                            create the application service principal.
                        </List.Item>
                    </List>
                </List.Item>
                <List.Item>
                    On the App registration page for the application copy the{" "}
                    <b>Directory (tenant) ID</b> to <b>Oasis Defender</b>:
                </List.Item>
                <PasswordInput
                    withAsterisk
                    label="Tenant ID"
                    placeholder=""
                    {...form.getInputProps("azure_tenant_id")}
                />
                <br />
                <List.Item>
                    On the App registration page for the application copy the{" "}
                    <b>Application (client) ID</b> to <b>Oasis Defender</b>:
                </List.Item>
                <PasswordInput
                    withAsterisk
                    label="Client ID"
                    placeholder=""
                    {...form.getInputProps("azure_client_id")}
                />
                <br />
                <List.Item>
                    Add a new client secret:
                    <List listStyleType="disc">
                        <List.Item>
                            On the <b>Certificates & secrets</b> page
                        </List.Item>
                        <List.Item>
                            Select <b>+ New client secret</b> the{" "}
                            <b>Add a client secret</b> dialog will pop out from
                            the right-hand side of the page. In the dialogue:
                            <br />
                            for <b>Description</b> enter{" "}
                            <i>Oasis Defender app</i>,
                            <br />
                            for <b>Expires</b> select <i>24 months</i>.
                        </List.Item>
                        <List.Item>
                            Select <b>Add</b> to add the secret.
                        </List.Item>
                        <List.Item>
                            On the <b>Certificates & secrets</b> page, you will
                            be shown the value of the client secret. It will be
                            shown only once! Insert the secret <b>Value</b> into{" "}
                            <b>Oasis Defender:</b>
                        </List.Item>
                    </List>
                </List.Item>
                <PasswordInput
                    withAsterisk
                    label="Client Secret"
                    placeholder=""
                    {...form.getInputProps("azure_client_secret")}
                />
                <br />
                <List.Item>
                    Assign roles to the application service principal:
                    <List listStyleType="disc">
                        <List.Item>
                            Navigate to <b>Resource groups</b>
                        </List.Item>
                        <List.Item>
                            {" "}
                            On the page for the resource group, which should be
                            added to Oasis Defender, select{" "}
                            <b>Access control (IAM)</b> from the left-hand menu
                        </List.Item>
                        <List.Item>
                            Select the <b>Role assignments</b> tab{" "}
                        </List.Item>
                        <List.Item>
                            Select <b>+ Add</b> from the top menu and then{" "}
                            <b>Add role assignment</b> from the resulting
                            drop-down menu{" "}
                        </List.Item>
                        <List.Item>
                            To create a <b>read-only</b> account: <br />
                            Select role <b>Reader</b> and press <b>Next</b>{" "}
                            button.
                        </List.Item>
                        <List.Item>
                            The Select text box can be used to filter the list
                            of users and groups inyour subscription. Type{" "}
                            <i>Oasis Defender</i>.
                        </List.Item>
                        <List.Item>
                            Select the service principal associated with{" "}
                            <b>Oasis Defender</b> application.
                        </List.Item>
                        <List.Item>
                            Select <b>Select</b> at the bottom of the dialog to
                            continue
                        </List.Item>
                        <List.Item>
                            The service principal will now show as selected on
                            the <b>Add role assignment</b> screen
                        </List.Item>
                        <List.Item>
                            Select <b>Review + assign</b> to go to the final
                            page and then <b>Review + assign</b> again to
                            complete the process.
                        </List.Item>
                    </List>
                </List.Item>
                <br />
                <Text>
                    For more information, visit the{" "}
                    <a href="https://learn.microsoft.com/en-us/azure/developer/python/sdk/authentication-on-premises-apps?tabs=azure-portal">
                        Azure Help page
                    </a>
                </Text>
            </List>
        );
    }
    function cloudConnectHuaweiManual(): React.ReactNode {
        return (
            <Text maw="30rem">
                <p>
                    To use Oasis Defender, you must have Huawei Cloud account as
                    well as the Access Key (AK) and Secret key (SK) of the
                    Huawei Cloud account. You can create an Access Key in the
                    Huawei Cloud console. For more information, see{" "}
                    <Anchor
                        href="https://support.huaweicloud.com/en-us/usermanual-ca/en-us_topic_0046606340.html"
                        target="_blank"
                    >
                        Huawei Cloud User Manual (Access Keys)
                    </Anchor>
                    .
                </p>
                <p>
                    Access Key is the access key ID for your account. <br />
                    Secret Key is the secret access key for your account. <br />
                    Project ID is the ID of the project you want to run,
                    depending on your region. If not specified, it will be taken
                    automatically. To obtain Project ID, see{" "}
                    <Anchor
                        href="https://support.huaweicloud.com/eu/oce_faq/en-us_topic_0113714841.html"
                        target="_blank"
                    >
                        Huawei Cloud User Manual (How Do I Obtain a Project Name
                        and Project ID?)
                    </Anchor>
                </p>
            </Text>
        );
    }

    function CloudConnectGoogleManual() {
        const getProjectIdText = "gcloud projects list";
        const createServiceAccountText = `gcloud iam service-accounts create oasisdefender --project [PROJECT-ID] --description="Service account for Oasis Defender" --display-name="oasisdefender"
gcloud projects add-iam-policy-binding [PROJECT-ID] --member="serviceAccount:oasisdefender@[PROJECT-ID].iam.gserviceaccount.com" --role="roles/viewer"
gcloud iam service-accounts keys create oasisdefender.json --iam-account oasisdefender@[PROJECT-ID].iam.gserviceaccount.com`;
        const [projectID, setProjectID] = useState("");

        const [
            createServiceAccountTextWithProjectID,
            setCreateServiceAccountTextWithProjectID,
        ] = useState(createServiceAccountText);

        useEffect(
            () =>
                setCreateServiceAccountTextWithProjectID(
                    createServiceAccountText.replaceAll(
                        "[PROJECT-ID]",
                        projectID ? projectID : "[PROJECT-ID]"
                    )
                ),
            [projectID]
        );

        return (
            <Text>
                <List type="ordered" w="90%">
                    <List.Item w="100%">
                        <Anchor
                            href="https://cloud.google.com/sdk/docs/install-sdk"
                            target="_blank"
                        >
                            Configure gcloud CLI
                        </Anchor>
                    </List.Item>
                    <List.Item w="100%">Obtain Project ID:</List.Item>
                    <CopyButton value={getProjectIdText}>
                        {({ copied, copy }) => (
                            <Button
                                color={copied ? "teal" : "blue"}
                                onClick={copy}
                            >
                                {copied ? "Code copied" : "Copy code"}
                            </Button>
                        )}
                    </CopyButton>
                    <Code block mt="sm" mb="sm">
                        {getProjectIdText}
                    </Code>
                    <TextInput
                        w="100%"
                        label="Project ID"
                        placeholder="Enter [Project ID] there"
                        value={projectID}
                        onChange={(event) =>
                            setProjectID(event.currentTarget.value)
                        }
                    />
                    <List.Item w="100%">
                        Create a service account for OasisDefender:
                    </List.Item>
                    <CopyButton value={createServiceAccountTextWithProjectID}>
                        {({ copied, copy }) => (
                            <Button
                                color={copied ? "teal" : "blue"}
                                onClick={copy}
                            >
                                {copied ? "Code copied" : "Copy code"}
                            </Button>
                        )}
                    </CopyButton>
                    <Code block mt="sm" mb="sm">
                        {createServiceAccountTextWithProjectID}
                    </Code>
                    <List.Item w="100%">
                        Paste the contents of the local file
                        "oasisdefender.json" into OasisDefender
                    </List.Item>
                </List>
            </Text>
        );
    }

    function CloudConnectDigitalOceanManual() {
        return (
            <>
                <Text>To connect DigitalOcean cloud to OasisDefender:</Text>
                <List type="ordered" w="90%">
                    <List.Item>
                        Create Personal Access Token with <b>Read</b> scope in
                        DigitalOcean console<br/>
                        <Anchor
                            href="https://cloud.digitalocean.com/account/api/tokens"
                            target="_blank"
                        >
                            (API Keys — Tokens)
                        </Anchor>{" "}
                    </List.Item>
                    <List.Item>
                        Create Spaces Access Key in DigitalOcean console<br/>
                        <Anchor
                            href="https://cloud.digitalocean.com/account/api/spaces"
                            target="_blank"
                        >
                            (API Keys — Spaces Keys)
                        </Anchor>{" "}
                    </List.Item>
                </List>
            </>
        );
    }
};
