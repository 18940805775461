import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import { HEADER_HEIGHT } from "../components/Header";
import {
    Alert,
    Box,
    Button,
    Group,
    Loader,
    Paper,
    ScrollArea,
    Space,
    Tooltip,
    useMantineTheme,
} from "@mantine/core";
import PolicyMapFilters, {
    IClassifierExt,
} from "../components/PolicyMapFilters";
import { useClassifiers } from "../core/hooks/classifiers";
import { IconAlertTriangle, IconSettings } from "@tabler/icons-react";
import { usePolicyMap } from "../core/hooks/policymap";
import PolicyMapView from "../components/PolicyMapView";
import InitSettingsContext from "../core/initsettings/InitSettingsContext";
import { useEffectAfterMount } from "../core/hooks/useEffectAfterMount";
import { IPredefinedClassifiers } from "../core/models/IClassifier";
import MapMenu, { MapMenuItem } from "../components/MapMenu";

export function PolicyMap() {
    const theme = useMantineTheme();
    const [selected] = useState<string | undefined>(undefined);
    const [stage, setStage] = useState<
        "filtersLoading" | "filters" | "loading" | "view"
    >("filtersLoading");
    const {
        loading: classifiersLoading,
        error: classifiersError,
        classifiers,
        predefinedClassifiers,
    } = useClassifiers();

    const [classifiersExt, setClassifiersExt] = useState<IClassifierExt[]>([]);
    useEffect(() => {
        if (stage === "filtersLoading") {
            if (classifiers) {
                const newValue = classifiers.map((classifier) => ({
                    ...classifier,
                    isChecked: false,
                }));
                setClassifiersExt(newValue);
                if (predefinedClassifiers && predefinedClassifiers.length > 0) {
                    setTemplate(predefinedClassifiers[0]);
                }
            }
        }
    }, [classifiers, predefinedClassifiers, stage]);

    const {
        loading: classificationLoading,
        error: classificationError,
        data: classification,
        fetchData: fetchClassification,
        toogleChildren,
    } = usePolicyMap();

    const selectedRef = useRef(selected);

    useEffect(() => {
        selectedRef.current = selected;
    }, [selected]);

    const toogleClassifiers = (id: number) => {
        setClassifiersExt((oldValue) =>
            oldValue.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isChecked: !item.isChecked,
                    };
                }
                return item;
            })
        );
    };

    const reorderClassifiers = (from: number, to: number) => {
        setClassifiersExt((oldValue) => {
            const updatedClassifiers = [...oldValue];
            const [movedItem] = updatedClassifiers.splice(from, 1);
            updatedClassifiers.splice(to, 0, movedItem);
            return updatedClassifiers;
        });
    };

    const showData = useCallback(() => {
        fetchClassification(
            classifiersExt
                .filter((item) => item.isChecked)
                .map((item) => item.id)
        );
    }, [classifiersExt, fetchClassification]);

    useEffect(() => {
        if (stage === "loading") {
            showData();
            setStage("view");
        }
    }, [stage, showData]);

    const setTemplate = (template: IPredefinedClassifiers) => {
        setClassifiersExt((oldValue) => {
            const selectedIds = template.selection;

            const selectedElements = selectedIds
                .map((id) => {
                    const element = oldValue.find((item) => item.id === id);
                    if (element) {
                        return {
                            ...element,
                            isChecked: true,
                        };
                    }
                    return null;
                })
                .filter((item): item is IClassifierExt => item !== null);

            const restElements = oldValue
                .filter((item) => !selectedIds.includes(item.id))
                .map((item) => ({
                    ...item,
                    isChecked: false,
                }));

            return [...selectedElements, ...restElements];
        });
        setStage("loading");
    };

    const { demoStateCurrent } = useContext(InitSettingsContext);
    useEffectAfterMount(() => {
        if (stage === "view") {
            setStage("loading");
        }
    }, [demoStateCurrent]);

    let content: React.ReactNode = <></>;
    const getLoader = () => (
        <Loader
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        />
    );
    const getError = (error: string) => (
        <ScrollArea
            h="100%"
            type="auto"
            offsetScrollbars
            pt="1rem"
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Cannot get classifiers"
                color="red"
                m={"xs"}
            >
                {error}
            </Alert>
        </ScrollArea>
    );

    let wasSelectedTemplate = false;
    const selectedIds = classifiersExt
        .filter((item) => item.isChecked)
        .map((el) => el.id);
    const arraysEqual = (a: number[], b: number[]) =>
        a.length === b.length && a.every((val, index) => val === b[index]);

    const menuItems: MapMenuItem[] =
        predefinedClassifiers?.map((template) => {
            const isActive = arraysEqual(selectedIds, template.selection);
            if (isActive) {
                wasSelectedTemplate = true;
            }
            return {
                id: template.id,
                label: template.name,
                description: template.description,
                isActive: isActive,
                onClick: () => setTemplate(template),
            };
        }) ?? [];

    menuItems.push({
        id: "custom",
        label: "Custom",
        description: "Customise your own classification",
        icon: <IconSettings size="1em" />,
        isActive: !wasSelectedTemplate,
        isVisible: stage === "view",
        onClick: () => setStage("filters"),
    });

    if (stage === "filtersLoading" || stage === "loading") {
        content = getLoader();
    } else if (stage === "filters") {
        if (classifiersLoading) {
            content = getLoader();
        } else {
            if (classifiersError) {
                content = getError(classifiersError);
            } else {
                content = (
                    <>
                        <MapMenu menuItems={menuItems} />

                        <PolicyMapFilters
                            classifiers={classifiersExt}
                            toogle={toogleClassifiers}
                            reorder={reorderClassifiers}
                        />
                        <Space h="4rem" />

                        <Button
                            size="md"
                            radius="md"
                            m="md"
                            onClick={() => setStage("loading")}
                            sx={{
                                "--width": "15rem",
                                width: "var(--width)",
                                position: "fixed",
                                bottom: "0.2rem",
                                left: "calc(50% - var(--width) / 2)",
                            }}
                            disabled={classifiersExt.every(
                                (classifier) => !classifier.isChecked
                            )}
                        >
                            Show data
                        </Button>
                    </>
                );
            }
        }
    } else if (stage === "view") {
        if (classificationLoading) {
            content = getLoader();
        } else {
            if (classificationError || !classification) {
                content = getError(classificationError ?? "unknown error");
            } else {
                content = (
                    <div
                        style={{
                            width: "100dvw",
                            height: `calc(100dvh - ${HEADER_HEIGHT})`,
                            position: "relative",
                            overflow: "hidden",
                        }}
                    >
                        <PolicyMapView
                            data={classification.scheme}
                            lines={classification.lines}
                            toogleChildren={toogleChildren}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: 0,
                                right: 0,
                            }}
                        >
                            <MapMenu menuItems={menuItems} />
                        </Box>
                    </div>
                );
            }
        }
    }

    return content;
}
